import React, { Component } from "react";

import Contractor from "../components/elements/Contractor/Contractor";
import Buyer from "../components/elements/Buyer/Buyer";
import Employer from "../components/elements/Employer/Employer";

import ConfirmContractor from "../components/ConfirmContractor";
import Confirm from "../components/Confirm";
import SelectedForm from "../components/SelectedForm";
import HouseDetails from "../components/HouseDetails";

import Soratvaziat from "../components/elements/Employer/Soratvaziat";
import Nemodar from "../components/elements/Employer/Nemodar";
import Ahjam from "../components/elements/Employer/Ahjam";
import Pardakhti from "../components/elements/Employer/Pardakhti";
import Daryafti from "../components/elements/Employer/Daryafti";
import Vahedha from "../components/elements/Employer/Vahedha";
import Shahrak from "../components/elements/Employer/Shahrak";
import Reports from "../components/elements/Buyer/Reports";

export class Home extends Component {
  constructor() {
    super();
    this.state = {
      step: 1,
      options: [],
      kabinetsarr: [],
      uniqkabinets: [],
      // cooling: [],
      uniqcooling: [],
      datas: [],
      items: [],
    };
    this.onValueChange = this.onValueChange.bind(this);
  }

  clearState() {
    this.setState({
      options: [],
      uniqkabinets: [],
      uniqcooling: [],
      uniqOptions: [],
      datas: [],
      size: undefined,
    });
  }

  //Proced to Next step
  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1,
    });
  };

  //Proced to Prev step
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
    this.clearState();
  };

  onValueChange = (option) => (event) => {
    this.setState({
      [option]: event.target.value,
    });
  };

  handleCheck = (options_checked, val) => (event) => {
    this.setState({
      [options_checked]: event.target.checked,
    });
    if (event.target.checked === true) {
      if (options_checked === "cooling") {
        this.state.options.push(event.target.value);

        let uniqs = this.state.options.filter(
          (item, index) => this.state.options.indexOf(item) === index
        );
        this.setState({
          uniqcooling: uniqs,
        });
      }
      if (options_checked === "kabinets") {
        this.state.kabinetsarr.push(event.target.value);
        let uniqs = this.state.kabinetsarr.filter(
          (item, index) => this.state.kabinetsarr.indexOf(item) === index
        );
        this.setState({
          uniqkabinets: uniqs,
        });
      }
    }

    if (event.target.checked === false) {
      if (options_checked === "kabinets") {
        let array = [...this.state.uniqkabinets]; // make a separate copy of the array
        let index = array.indexOf(event.target.value);
        if (index !== -1) {
          array.splice(index, 1);
          this.setState({ uniqkabinets: array });
        }
        console.log(array, this.state.uniqkabinets);
        // this.state.uniqkabinets.pop(val);
      }
      if (options_checked === "cooling") {
        let array = [...this.state.uniqcooling]; // make a separate copy of the array
        let index = array.indexOf(event.target.value);
        if (index !== -1) {
          array.splice(index, 1);
          this.setState({ uniqcooling: array });
        }
      }
      this.state.options.pop(val);
      // this.state.cooling.pop(event.target.value);
    }
  };

  gotoStep2 = () => {
    this.setState({
      step: 2,
      options: [],
      kabinets: [],
      // cooling: [],
      uniqOptions: [],
    });
    this.clearState();
  };

  gotoSize = () => {
    this.setState({
      step: 4,
    });
  };

  gotoConfirm = () => {
    this.setState({
      step: 5,
    });
  };

  reset = () => {
    this.setState({
      options: [],
      kabinetsarr: [],
      uniqkabinets: [],
      uniqcooling: [],
      uniqOptions: [],
      size: undefined,
      step: 1,
      items: [],
    });
    this.clearState();
    this.setState({
      step: 1,
    });
  };

  componentWillUpdate(nextProps, nextState) {
    // console.log("Next STATE :", nextState);
    // // console.log("STATE : " , this.state);
    // console.log(nextProps);
  }

  onPagenumberChange = (option, selectedPage) => () => {
    this.setState({
      [option]: selectedPage,
    });
    this.nextStep();
  };

  render() {
    const { step } = this.state;
    const { category, renovation_type, roof_type } = this.state;
    const values = { category, renovation_type, roof_type };
    let { datas, ...other } = this.state;

    switch (step) {
      case 1:
        return (
          <SelectedForm
            nextStep={this.nextStep}
            onValueChange={this.onValueChange}
            values={values}
          ></SelectedForm>
        );
      case 2:
        switch (category) {
          case "employer":
            return (
              <Employer
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                values={values}
                onValueChange={this.onValueChange}
                handleCheck={this.handleCheck}
              ></Employer>
            );
          case "buyer":
            return (
              <Buyer
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                values={values}
                onValueChange={this.onValueChange}
                handleCheck={this.handleCheck}
                data={other}
              ></Buyer>
            );
          case "contractor":
            return (
              <Contractor
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                values={values}
                onValueChange={this.onValueChange}
                handleCheck={this.handleCheck}
              ></Contractor>
            );
          default:
            break;
        }
        break;
      case 3:
        if (category === "contractor") {
          return (
            <ConfirmContractor
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              onValueChange={this.onValueChange}
              gotoStep2={this.gotoStep2}
              data={other}
              datas={datas}
              reset={this.reset}
              add={this.add}
            ></ConfirmContractor>
          );
        } else {
          return (
            <Confirm
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              onValueChange={this.onValueChange}
              gotoStep2={this.gotoStep2}
              data={other}
              datas={datas}
              reset={this.reset}
              add={this.add}
            ></Confirm>
          );
        }
      case 4:
        return (
          <HouseDetails
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            onValueChange={this.onValueChange}
            gotoStep2={this.gotoStep2}
            data={other}
            datas={datas}
            reset={this.reset}
            add={this.add}
            onPagenumberChange={this.onPagenumberChange}
          ></HouseDetails>
        );
      case 5:
        switch (this.state.page) {
          case "soratvaziat":
            return (
              <Soratvaziat
                nextStep={this.inNextStep}
                prevStep={this.prevStep}
              ></Soratvaziat>
            );
          case "nemodar":
            return (
              <Nemodar
                nextStep={this.inNextStep}
                prevStep={this.prevStep}
              ></Nemodar>
            );
          case "ahjam":
            return (
              <Ahjam
                nextStep={this.inNextStep}
                prevStep={this.prevStep}
              ></Ahjam>
            );
          case "pardakhti":
            return (
              <Pardakhti
                nextStep={this.inNextStep}
                prevStep={this.prevStep}
              ></Pardakhti>
            );
          case "daryafti":
            return (
              <Daryafti
                nextStep={this.inNextStep}
                prevStep={this.prevStep}
              ></Daryafti>
            );
          case "vahedha":
            return (
              <Vahedha
                nextStep={this.inNextStep}
                prevStep={this.prevStep}
              ></Vahedha>
            );
          case "shahrak":
            return (
              <Shahrak
                nextStep={this.inNextStep}
                prevStep={this.prevStep}
              ></Shahrak>
            );
          case "reports":
            return (
              <Reports
                nextStep={this.inNextStep}
                prevStep={this.prevStep}
              ></Reports>
            );
          default:
            break;
        }
        break;
      default:
        break;
    }
  }
}

export default Home;
