import React, { Component } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

// import logo2 from "../../assets/logo-2.svg";

import "./header.css";

export class header extends Component {
  render() {
    return (
      <AppBar position="static" className="head">
        <div class="head-wraper">
          <Typography variant="h5" color="inherit" className="header-user">
            {this.props.User}
          </Typography>
          <a class="header-logo" href="https://housebuilder.mkhedmati.ir">
            {/* <img src={logo2} alt="Logo"/> */}
          </a>
          <Toolbar variant="dense" class="header-title-wraper">
            <Typography variant="h5" color="inherit" className="header-title">
              {/* {this.props.Title} */}
            </Typography>
          </Toolbar>
        </div>
      </AppBar>
    );
  }
}

export default header;
