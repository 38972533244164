import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Calendar } from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";

import calendar from "../../../assets/Buyer/calendar.jpg";

import "../elements.css";

export class Pardakhti extends Component {
  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  handlerItesm = (key, item, children) => {
    if (item === "12") {
      console.log(key, item);
    }
    return children;
  };

  render() {
    return (
      <React.Fragment>
        <div className="main">
          <div className="main-box">
            <div className="container space-2 space-md-3">
              <div className="steps">
                <div className="main-screen">
                  <br />
                  <Typography variant="h4" className="title">
                    نمایش صورت وضعیت
                  </Typography>
                  <br />
                  <div className="pardakhtiha">
                    <Calendar
                      mapDays={({ date, currentMonth }) => {
                        let props = {};

                        props.style = {
                          borderRadius: "3px",
                          backgroundColor:
                            date.month.index === currentMonth.index
                              ? "#ccc"
                              : "",
                        };

                        if (date.day === 12)
                          props.style = {
                            borderRadius: "3px",
                            color: "white",
                            backgroundColor: "red",
                          };

                        return props;
                        // let color;
                        // if (date.day === 12) color = "green";

                        // if (color)
                        //   return { className: "highlight highlight-" + color };
                      }}
                      calendar={persian}
                      locale={persian_fa}
                      calendarPosition="bottom-right"
                    />
                    <Button
                      variant="outlined"
                      color="primary"
                      className="info_btns"
                      href={calendar}
                    >
                      دانلود فایل پرداختی
                    </Button>
                    <div className="fotter-back">
                      <Button
                        variant="contained"
                        color="primary"
                        className="footer-btn-back"
                        onClick={this.back}
                      >
                        قبل
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Pardakhti;
