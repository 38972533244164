import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import FormControl from "@material-ui/core/FormControl";

import "../elements.css";

export class PersonalInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setOpen: false,
    };
  }

  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  handleChange = (prop) => (event) => {
    this.setState({
      [prop]: event.target.value,
    });
  };

  Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({
      setOpen: false,
    });
  };

  handleLogin = (e) => {
    if (this.state.username === "admin" && this.state.password === "admin") {
      e.preventDefault();
      this.props.nextStep();
    } else {
      this.setState({
        setOpen: true,
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="main">
          <div className="main-box">
            <div className="container space-2 space-md-3">
              <div className="steps">
                <div className="main-screen">
                  <br />
                  <Typography
                    variant="h4"
                    className="title font-weight-bold Title-elements"
                  >
                    تکمیل اطلاعات فردی
                  </Typography>
                  <br />
                  <div className="allItems">
                    <Snackbar
                      open={this.state.setOpen}
                      autoHideDuration={6000}
                      onClose={this.handleClose}
                    >
                      <this.Alert onClose={this.handleClose} severity="error">
                        نام کاربری یا رمز عبور اشتباه است
                      </this.Alert>
                    </Snackbar>
                    <Grid container spacing={1} alignItems="flex-end">
                      <Grid item>
                        <FormControl>
                          <InputLabel
                            htmlFor="standard-adornment-firstname"
                            className="custom-labelinput"
                          >
                            نام
                          </InputLabel>
                          <Input
                            id="standard-adornment-firstname"
                            value={this.state.firstname}
                            onChange={this.handleChange("firstname")}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item>
                        <FormControl>
                          <InputLabel
                            htmlFor="standard-adornment-lastname"
                            className="custom-labelinput"
                          >
                            نام خانوادگی
                          </InputLabel>
                          <Input
                            id="standard-adornment-lastname"
                            value={this.state.lastname}
                            onChange={this.handleChange("lastname")}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item>
                        <FormControl>
                          <InputLabel
                            htmlFor="standard-adornment-father"
                            className="custom-labelinput"
                          >
                            نام پدر
                          </InputLabel>
                          <Input
                            id="standard-adornment-father"
                            value={this.state.father}
                            onChange={this.handleChange("father")}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="flex-end">
                      <Grid item>
                        <FormControl>
                          <InputLabel
                            htmlFor="standard-adornment-codemelli"
                            className="custom-labelinput"
                          >
                            کد ملی
                          </InputLabel>
                          <Input
                            id="standard-adornment-codemelli"
                            value={this.state.codemelli}
                            onChange={this.handleChange("codemelli")}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item>
                        <FormControl>
                          <InputLabel
                            htmlFor="standard-adornment-birthday"
                            className="custom-labelinput"
                          >
                            تاریخ تولد
                          </InputLabel>
                          <Input
                            id="standard-adornment-birthday"
                            value={this.state.birthday}
                            onChange={this.handleChange("birthday")}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item>
                        <FormControl>
                          <InputLabel
                            htmlFor="standard-adornment-birthcity"
                            className="custom-labelinput"
                          >
                            محل تولد
                          </InputLabel>
                          <Input
                            id="standard-adornment-birthcity"
                            value={this.state.birthcity}
                            onChange={this.handleChange("birthcity")}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="flex-end">
                      <Grid item>
                        <FormControl>
                          <InputLabel
                            htmlFor="standard-adornment-codecity"
                            className="custom-labelinput"
                          >
                            محل صدور
                          </InputLabel>
                          <Input
                            id="standard-adornment-codecity"
                            value={this.state.codecity}
                            onChange={this.handleChange("codecity")}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item>
                        <FormControl>
                          <InputLabel
                            htmlFor="standard-adornment-family"
                            className="custom-labelinput"
                          >
                            تعداد خانوار
                          </InputLabel>
                          <Input
                            id="standard-adornment-family"
                            value={this.state.family}
                            onChange={this.handleChange("family")}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item>
                        <FormControl>
                          <InputLabel
                            htmlFor="standard-adornment-phonenumber"
                            className="custom-labelinput"
                          >
                            شماره موبایل
                          </InputLabel>
                          <Input
                            id="standard-adornment-phonenumber"
                            value={this.state.phonenumber}
                            onChange={this.handleChange("phonenumber")}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="flex-end">
                      <Grid item>
                        <FormControl>
                          <InputLabel
                            htmlFor="standard-adornment-city"
                            className="custom-labelinput"
                          >
                            شهر محل سکونت
                          </InputLabel>
                          <Input
                            id="standard-adornment-city"
                            value={this.state.city}
                            onChange={this.handleChange("city")}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item>
                        <FormControl>
                          <InputLabel
                            htmlFor="standard-adornment-phone"
                            className="custom-labelinput"
                          >
                            تلفن ثابت
                          </InputLabel>
                          <Input
                            id="standard-adornment-phone"
                            value={this.state.phone}
                            onChange={this.handleChange("phone")}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item>
                        <FormControl>
                          <InputLabel
                            htmlFor="standard-adornment-zipcode"
                            className="custom-labelinput"
                          >
                            کد پستی
                          </InputLabel>
                          <Input
                            id="standard-adornment-zipcode"
                            value={this.state.zipcode}
                            onChange={this.handleChange("zipcode")}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid className="address">
                      <Grid item>
                        <FormControl>
                          <InputLabel
                            htmlFor="standard-adornment-address"
                            className="custom-labelinput"
                          >
                            آدرس محل سکونت
                          </InputLabel>
                          <Input
                            id="standard-adornment-address"
                            value={this.state.address}
                            onChange={this.handleChange("address")}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <div className="fotter-back">
                      <Button
                        variant="contained"
                        color="primary"
                        className="footer-btn-back"
                        onClick={this.back}
                      >
                        قبل
                      </Button>
                    </div>
                    <div className="fotter">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.continue}
                        className="footer-btn-next"
                      >
                        بعدی
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PersonalInfo;
