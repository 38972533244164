import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import charts from "../../../assets/employer/charts.mp4";

import "../elements.css";

export class Nemodar extends Component {
  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  componentDidMount() {
    console.log(this.props);
  }

  render() {
    return (
      <React.Fragment>
        <div className="main">
          <div className="main-box">
            <div className="container space-2 space-md-3">
              <div className="steps">
                <div className="main-screen">
                  <br />
                  <Typography variant="h4" className="title">
                    نمایش نمودارها
                  </Typography>
                  <br />
                  <div className="allItems">
                    <video width="90%">
                      <source src={charts}></source>
                    </video>
                    <Button
                      variant="outlined"
                      color="primary"
                      className="info_btns"
                      href="#"
                    >
                      دانلود فایل نمودارها
                    </Button>
                    <div className="fotter-back">
                      <Button
                        variant="contained"
                        color="primary"
                        className="footer-btn-back"
                        onClick={this.back}
                      >
                        قبل
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Nemodar;
