import React, { Component } from "react";
// import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
// import Select from "@material-ui/core/Select";
// import NativeSelect from "@material-ui/core/NativeSelect";

import "./Select.css";
import { Fragment } from "react";
// import { render } from "@testing-library/react";
import { Typography } from "@material-ui/core";

// import basement from "../assets/conf item/004-basement.svg";
import kitchen from "../assets/conf item/005-kitchen.svg";
import user from "../assets/svg icons/icons8-account-100.svg";
import contractor from "../assets/svg icons/icons8-engineer-100_1.svg";

import house from "../assets/conf item/001-house.svg";
import bedrooms from "../assets/conf item/icons8-bedroom-64.svg";
import floorplan from "../assets/conf item/007-draws.svg";
import cooler from "../assets/conf item/icons8-air-cooler-64.svg";

export class NativeSelects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project: 0,
      name: "",
      listSelect: [],
    };
    this.handleChange = this.handleChange.bind(this);
  }

  renderList = (selected) => {
    let content = [];
    for (let i = 0; i < selected.length; i++) {
      const item = selected[i];
      content.push(
        <Typography variant="p" className="item-question">
          {item}, &nbsp;
        </Typography>
      );
      if (i === selected.length - 1) {
        content.pop(
          <Typography variant="p" className="item-question">
            {item} &nbsp;
          </Typography>
        );
        content.push(
          <Typography variant="p" className="item-question">
            {item} &nbsp;
          </Typography>
        );
      }
    }
    return content;
  };

  itemList = (question, selected) => {
    return (
      <div className="row g-0 bg-white items">
        <div className="col-2 col-md-2">
          {/* {this.Questionicons(this.props.data.category)} */}
          {this.Questionicons(question)}
        </div>
        <div className="col-sm-10 col-md-10 text-right ">
          <Typography variant="h5" className="item-question">
            {question}
          </Typography>
          <div className="">{this.renderList(selected)}</div>
        </div>
      </div>
    );
  };

  item = (question, selected) => {
    return (
      <div className="row g-0 bg-white items">
        <div className="col-2 col-md-2">
          {/* {this.Questionicons(this.props.data.category)} */}
          {this.Questionicons(question)}
        </div>
        <div className="col-sm-10 col-md-10 text-right ">
          <Typography variant="h6" className="item-question">
            {question}
          </Typography>
          <Typography variant="p" className="item-question">
            {selected}
          </Typography>
        </div>
      </div>
    );
  };

  handleChange = (event) => {
    const name = event.target.name;
    this.setState({
      ...this.state,
      [name]: event.target.value,
    });
  };

  icons = (category) => {
    switch (category) {
      case "buyer":
        return <img className="icon" src={user} alt="mast dg" />;
      case "contractor":
        return <img className="icon" src={contractor} alt="mast dg" />;
      default:
        break;
    }
  };

  renderTitle = (category) => {
    if (category === "buyer") {
      return (
        <div className="items-head">
          {this.icons(category)}
          <Typography variant="h5" className="head-title">
            متقاضی
          </Typography>
        </div>
      );
    }
    return (
      <div className="items-head">
        {this.icons(category)}
        <Typography variant="h5" className="head-title">
          پیمانکار
        </Typography>
      </div>
    );
  };

  renderItems = (data) => {
    switch (data.category) {
      case "buyer":
        return (
          <div>
            {this.item("متراژ", data.house_size)}
            {this.item("تعداد اتاق خواب", data.bedroom)}
            {this.item("کفپوش", data.floor)}
            {this.itemList("سیستم سرمایش/گرمایش", data.uniqcooling)}
            {this.itemList("امکانات دیواری", data.uniqkabinets)}
          </div>
        );
      case "contractor":
        return (
          <div>
            {this.item("متراژ", data.house_size)}
            {/* {this.item("تعداد اتاق خواب", data.bedroom)}
            {this.item("کفپوش", data.floor)}
            {this.itemList("سیستم سرمایش/گرمایش", data.uniqOptions)}
            {this.item("امکانات دیواری", data.kabinets)} */}
          </div>
        );
      default:
        break;
    }
  };

  Questionicons = (category) => {
    // console.log(category);
    switch (category) {
      case "متراژ":
        return <img className="icon" src={house} alt="mast dg" />;
      case "تعداد اتاق خواب":
        return <img className="icon" src={bedrooms} alt="mast dg" />;
      case "کفپوش":
        return <img className="icon" src={floorplan} alt="mast dg" />;
      case "سیستم سرمایش/گرمایش":
        return <img className="icon" src={cooler} alt="mast dg" />;
      case "امکانات دیواری":
        return <img className="icon" src={kitchen} alt="mast dg" />;
      default:
        break;
    }
    // if (category == "buyer") {
    //   return <img className="icon" src={kitchen} alt="mast dg" />;
    // }
    // return <img className="icon" src={basement} alt="mast dg" />;
  };

  componentDidMount() {
    // console.log(this.props);
  }

  componentDidUpdate() {}

  createList = () => {
    return (
      <div class="conf-item">
        <div className="revType items">
          {this.renderTitle(this.props.datas[this.state.project].category)}
        </div>
        {this.renderItems(this.props.datas[this.state.project])}
      </div>
    );
  };

  render() {
    return (
      <Fragment>
        <FormControl variant="outlined">
          {/* <InputLabel htmlFor="project-selector">Project</InputLabel>
          <Select
            native
            value={this.state.project}
            onChange={this.handleChange}
            label="project"
            inputProps={{
              name: "project",
              id: "project-selector",
            }}
          >
            {this.props.data.items}
          </Select> */}
          {this.props.datas.length !== 0 ? (
            this.createList()
          ) : (
            <h1>در حال دریافت اطلاعات</h1>
          )}
        </FormControl>
      </Fragment>
    );
  }
}

export default NativeSelects;
