import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import CustomCardRadio from "../../customCard/CustomCardRadio";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";

import reportsimg from "../../../assets/svg conf icons/icons8-pie-chart-report-100.svg";
import mapimg from "../../../assets/svg conf icons/icons8-floor-plan-100.svg";
import analyseimg from "../../../assets/svg conf icons/icons8-google-analytics-logo-100.svg";
import finance from "../../../assets/svg/icons8-coins-100.svg";
import infoimg from "../../../assets/svg conf icons/icons8-books-100.svg";
import uploaddocs from "../../../assets/svg/icons8-upload-document-100.svg";
import shahrak from "../../../assets/svg/icons8-city-100.svg";
import rizmetre1khab from "../../../assets/employer/riz metre 1 khab.xlsx";
import rizmetre2khab from "../../../assets/employer/riz metre 2 khab.xlsx";
import rizmetre3khab from "../../../assets/employer/riz metre.xlsx";

import rizmetre from "../../../assets/employer/riz metreha.pdf";
import fehrestbaha from "../../../assets/employer/fehrest baha.jpg";

import "../elements.css";

export class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openMaps: false,
      openMetre: false,
      openReport: false,
      openDocuments: false,
      openFinance: false,
      openVariables: false,
      OpenVtour: false,
      link: "",
      displayBTN: "none",
    };
  }

  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };
  handleOpenModal = (option) => () => {
    this.setState({
      [option]: true,
    });
  };
  handleCloseModal = (option) => () => {
    this.setState({
      [option]: false,
    });
  };

  handleChange = (option) => (event) => {
    this.setState({
      [option]: event.target.value,
    });
  };

  updateLink = () => {
    if (this.state.metraj === 100) {
      switch (this.state.bedroom) {
        case 1:
          switch (this.state.floor) {
            case "موزائیک":
              this.setState((state) => {
                // Important: read `state` instead of `this.state` when updating.
                return { link: "http://Bimplus.ir/1mozaic" };
              });
              break;
            case "موکت":
              this.setState({
                link: "http://Bimplus.ir/parket1",
              });
              break;
            case "سرامیک":
              this.setState({
                link: "http://Bimplus.ir/1Ceramic",
              });
              break;
            case "پارکت":
              this.setState({
                link: "http://Bimplus.ir/parket1",
              });
              break;
            case "سیمان":
              this.setState({
                link: "http://Bimplus.ir/1siman",
              });
              break;
            default:
              break;
          }
          break;
        case 2:
          switch (this.state.floor) {
            case "موزائیک":
              this.setState({
                link: "http://Bimplus.ir/2mozaic",
              });
              break;
            case "موکت":
              this.setState({
                link: "http://Bimplus.ir/parket2",
              });
              break;
            case "سرامیک":
              this.setState({
                link: "http://Bimplus.ir/2ceramic",
              });
              break;
            case "پارکت":
              this.setState({
                link: "http://Bimplus.ir/parket2",
              });
              break;
            case "سیمان":
              this.setState({
                link: "http://Bimplus.ir/2siman",
              });
              break;
            default:
              break;
          }
          break;
        case 3:
          switch (this.state.floor) {
            case "موزائیک":
              this.setState({
                link: "http://Bimplus.ir/3mozic",
              });
              break;
            case "موکت":
              this.setState({
                link: "http://Bimplus.ir/parket3",
              });
              break;
            case "سرامیک":
              this.setState({
                link: "http://Bimplus.ir/3Ceramic",
              });
              break;
            case "پارکت":
              this.setState({
                link: "http://Bimplus.ir/parket3",
              });
              break;
            case "سیمان":
              this.setState({
                link: "http://Bimplus.ir/3siman",
              });
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
    } else {
      switch (this.state.bedroom) {
        case 1:
          switch (this.state.floor) {
            case "موزائیک":
              this.setState({
                link: "http://Bimplus.ir/1mozaic",
              });
              break;
            case "موکت":
              this.setState({
                link: "http://Bimplus.ir/parket1",
              });
              break;
            case "سرامیک":
              this.setState({
                link: "http://Bimplus.ir/1Ceramic",
              });
              break;
            case "پارکت":
              this.setState({
                link: "http://Bimplus.ir/parket1",
              });
              break;
            case "سیمان":
              this.setState({
                link: "http://Bimplus.ir/1siman",
              });
              break;
            default:
              break;
          }
          break;
        case 2:
          switch (this.state.floor) {
            case "موزائیک":
              this.setState({
                link: "http://Bimplus.ir/2mozaic",
              });
              break;
            case "موکت":
              this.setState({
                link: "http://Bimplus.ir/parket2",
              });
              break;
            case "سرامیک":
              this.setState({
                link: "http://Bimplus.ir/2ceramic",
              });
              break;
            case "پارکت":
              this.setState({
                link: "http://Bimplus.ir/parket2",
              });
              break;
            case "سیمان":
              this.setState({
                link: "http://Bimplus.ir/2siman",
              });
              break;
            default:
              break;
          }
          break;
        case 3:
          switch (this.state.floor) {
            case "موزائیک":
              this.setState({
                link: "http://Bimplus.ir/3mozic",
              });
              break;
            case "موکت":
              this.setState({
                link: "http://Bimplus.ir/parket3",
              });
              break;
            case "سرامیک":
              this.setState({
                link: "http://Bimplus.ir/3Ceramic",
              });
              break;
            case "پارکت":
              this.setState({
                link: "http://Bimplus.ir/parket3",
              });
              break;
            case "سیمان":
              this.setState({
                link: "http://Bimplus.ir/3siman",
              });
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
    }
    if (this.state.metraj !== undefined) {
      if (this.state.bedroom !== undefined) {
        if (this.state.floor !== undefined) {
          this.setState({
            displayBTN: "",
          });
        }
      }
    }
  };

  render() {
    const { onPagenumberChange } = this.props;

    return (
      <React.Fragment>
        <div className="main">
          <div className="main-box">
            <div className="container space-2 space-md-3">
              <div className="steps">
                <div className="main-screen">
                  <br />
                  {/* <Typography variant="h4" className="title">
                    تعدا اتاق خواب خود را انتخاب کنید
                  </Typography> */}
                  <br />
                  <div className="all_elements">
                    <Accordion className="root_accordion">
                      <AccordionSummary>
                        <Typography
                          variant="h5"
                          color="inherit"
                          className="subtitle"
                        >
                          گزارشات فنی
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="element">
                          <div className="inner_element">
                            <Accordion className="accordion_custom">
                              <AccordionSummary>
                                <CustomCardRadio
                                  img={reportsimg}
                                  Title={"اسناد فنی"}
                                ></CustomCardRadio>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={this.handleOpenModal("openMaps")}
                                >
                                  نقشه ها
                                </Button>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={this.handleOpenModal("openMetre")}
                                >
                                  متره ها
                                </Button>
                              </AccordionDetails>
                            </Accordion>
                            <Dialog
                              open={this.state.openMaps}
                              onClose={this.handleCloseModal("openMaps")}
                              aria-labelledby="form-dialog-title"
                              className="dialog_confirm"
                            >
                              <DialogTitle id="form-dialog-title">
                                مشاهده نقشه های پروژه
                              </DialogTitle>
                              <DialogContent>
                                <DialogContentText>
                                  {/* دانلود اطلاعات و نقشه‌ها */}
                                </DialogContentText>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  className="info_btns"
                                  onClick={onPagenumberChange(
                                    "page",
                                    "shahrak"
                                  )}
                                >
                                  پروژه
                                </Button>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  className="info_btns"
                                  onClick={onPagenumberChange(
                                    "page",
                                    "vahedha"
                                  )}
                                >
                                  واحدها
                                </Button>
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  onClick={this.handleCloseModal("openMaps")}
                                  color="primary"
                                >
                                  بستن
                                </Button>
                              </DialogActions>
                            </Dialog>
                            <Dialog
                              open={this.state.openMetre}
                              onClose={this.handleCloseModal("openMetre")}
                              aria-labelledby="form-dialog-title"
                              className="dialog_confirm"
                            >
                              <DialogTitle id="form-dialog-title">
                                مشاهده اطلاعات پروژه
                              </DialogTitle>
                              <DialogContent>
                                <DialogContentText>
                                  {/* دانلود اطلاعات و نقشه‌ها */}
                                </DialogContentText>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  className="info_btns"
                                  href={rizmetre}
                                >
                                  ریز متره‌ها
                                </Button>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  className="info_btns"
                                  href={fehrestbaha}
                                >
                                  فهرست بها
                                </Button>
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  onClick={this.handleCloseModal("openMetre")}
                                  color="primary"
                                >
                                  بستن
                                </Button>
                              </DialogActions>
                            </Dialog>
                          </div>
                          <div
                            variant="contained"
                            color="primary"
                            onClick={this.handleOpenModal("openDocuments")}
                          >
                            <CustomCardRadio
                              img={infoimg}
                              Title={"اسناد"}
                            ></CustomCardRadio>
                          </div>
                          <Dialog
                            open={this.state.openDocuments}
                            onClose={this.handleCloseModal("openDocuments")}
                            aria-labelledby="form-dialog-title"
                            className="dialog_confirm"
                          >
                            <DialogTitle id="form-dialog-title">
                              مشاهده اسناد
                            </DialogTitle>
                            <DialogContent>
                              <DialogContentText>
                                {/* دانلود اطلاعات و نقشه‌ها */}
                              </DialogContentText>
                              <Button
                                variant="outlined"
                                color="primary"
                                className="info_btns"
                                href={rizmetre}
                              >
                                مدارک
                              </Button>
                              <Button
                                variant="outlined"
                                color="primary"
                                className="info_btns"
                                href={fehrestbaha}
                              >
                                اطلاعات پروانه
                              </Button>
                            </DialogContent>
                            <DialogActions>
                              <Button
                                onClick={this.handleCloseModal("openDocuments")}
                                color="primary"
                              >
                                بستن
                              </Button>
                            </DialogActions>
                          </Dialog>
                          <div className="inner_element">
                            <Accordion className="accordion_custom">
                              <AccordionSummary>
                                <CustomCardRadio
                                  img={uploaddocs}
                                  Title={"کنترل پروژه"}
                                ></CustomCardRadio>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={this.handleOpenModal(
                                    "openVariables"
                                  )}
                                >
                                  بارگزاری مقادیر
                                </Button>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={this.handleOpenModal("openReport")}
                                >
                                  بارگزاری گزارش عملکردی
                                </Button>
                                <input
                                  className="uploadbtn"
                                  accept="image/*"
                                  id="contained-button-file"
                                  multiple
                                  type="file"
                                />
                                <label
                                  htmlFor="contained-button-file"
                                  className="uploadlabel"
                                >
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    component="span"
                                  >
                                    صورت وضعیت
                                  </Button>
                                </label>
                              </AccordionDetails>
                            </Accordion>
                          </div>
                          <Dialog
                            open={this.state.openVariables}
                            onClose={this.handleCloseModal("openVariables")}
                            aria-labelledby="form-dialog-title"
                            className="dialog_confirm"
                          >
                            <DialogTitle id="form-dialog-title">
                              بارگزاری مقادیر
                            </DialogTitle>
                            <DialogContent>
                              <DialogContentText>
                                {/* دانلود اطلاعات و نقشه‌ها */}
                              </DialogContentText>
                              <a
                                href="https://docs.google.com/spreadsheets/d/1HS14yXeZ-sy1AR3K4Lb3hixgSODPBbUgChu3ctf1e3k/edit?usp=sharing"
                                target={"_blank"}
                                rel="noreferrer"
                                className="abtn "
                              >
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  component="span"
                                >
                                  احجام
                                </Button>
                              </a>
                              <input
                                className="uploadbtn"
                                accept="image/*"
                                id="contained-button-file"
                                multiple
                                type="file"
                              />
                              <label
                                htmlFor="contained-button-file"
                                className="uploadlabel"
                              >
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  component="span"
                                >
                                  فهرست بها
                                </Button>
                              </label>
                            </DialogContent>
                            <DialogActions>
                              <Button
                                onClick={this.handleCloseModal("openVariables")}
                                color="primary"
                              >
                                بستن
                              </Button>
                            </DialogActions>
                          </Dialog>
                          <Dialog
                            open={this.state.openReport}
                            onClose={this.handleCloseModal("openReport")}
                            aria-labelledby="form-dialog-title"
                            className="dialog_confirm"
                          >
                            <DialogTitle id="form-dialog-title">
                              بارگزاری گزارش عملکردی
                            </DialogTitle>
                            <DialogContent>
                              <DialogContentText>
                                {/* دانلود اطلاعات و نقشه‌ها */}
                              </DialogContentText>
                              <input
                                className="uploadbtn"
                                accept="image/*"
                                id="contained-button-file"
                                multiple
                                type="file"
                              />
                              <label
                                htmlFor="contained-button-file"
                                className="uploadlabel"
                              >
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  component="span"
                                >
                                  صورت وضعیت
                                </Button>
                              </label>
                              <input
                                className="uploadbtn"
                                accept="image/*"
                                id="contained-button-file"
                                multiple
                                type="file"
                              />
                              <label
                                htmlFor="contained-button-file"
                                className="uploadlabel"
                              >
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  component="span"
                                >
                                  بارگزاری تصویر
                                </Button>
                              </label>
                              <a
                                href="https://dashboard.mkhedmati.ir"
                                target={"_blank"}
                                rel="noreferrer"
                                className="abtn"
                              >
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  component="span"
                                >
                                  پیشرفت پروژه
                                </Button>
                              </a>
                            </DialogContent>
                            <DialogActions>
                              <Button
                                onClick={this.handleCloseModal("openReport")}
                                color="primary"
                              >
                                بستن
                              </Button>
                            </DialogActions>
                          </Dialog>
                          <div className="inner_element">
                            <Accordion className="accordion_custom">
                              <AccordionSummary>
                                <CustomCardRadio
                                  img={analyseimg}
                                  Title={"مشاهده بهای تمام شده"}
                                ></CustomCardRadio>
                              </AccordionSummary>
                              <AccordionDetails>
                                <a href={rizmetre1khab}>
                                  <Button variant="outlined" color="primary">
                                    ریزمتره یک خواب
                                  </Button>
                                </a>
                                <a href={rizmetre2khab}>
                                  <Button variant="outlined" color="primary">
                                    ریزمتره دو خواب{" "}
                                  </Button>
                                </a>
                                <a href={rizmetre3khab}>
                                  <Button variant="outlined" color="primary">
                                    ریزمتره سه خواب{" "}
                                  </Button>
                                </a>
                              </AccordionDetails>
                            </Accordion>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion className="root_accordion">
                      <AccordionSummary>
                        <Typography
                          variant="h5"
                          color="inherit"
                          className="subtitle"
                        >
                          نگارخانه
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="element">
                          <a
                            href="http://bimplus.ir/9noor/"
                            target={"_blank"}
                            rel="noreferrer"
                            className="abtn"
                          >
                            <div variant="contained" color="primary">
                              <CustomCardRadio
                                img={shahrak}
                                Title={"تصاویر ۳ بعدی پروژه"}
                              ></CustomCardRadio>
                            </div>
                          </a>
                          <div
                            variant="contained"
                            color="primary"
                            onClick={this.handleOpenModal("OpenVtour")}
                          >
                            <CustomCardRadio
                              img={mapimg}
                              Title={"تصاویر ۳ بعدی واحد"}
                            ></CustomCardRadio>
                          </div>
                          <Dialog
                            open={this.state.OpenVtour}
                            onClose={this.handleCloseModal("OpenVtour")}
                            aria-labelledby="form-dialog-title"
                            className="dialog_confirm"
                          >
                            <DialogTitle id="form-dialog-title">
                              مشاهده تور مجازی
                            </DialogTitle>
                            <DialogContent>
                              <form>
                                <FormControl>
                                  <InputLabel id="demo-dialog-select-metraj">
                                    متراژ
                                  </InputLabel>
                                  <Select
                                    className="selection"
                                    labelId="demo-dialog-select-metraj"
                                    id="demo-dialog-select"
                                    value={this.state.metraj}
                                    onChange={this.handleChange("metraj")}
                                    input={<Input />}
                                  >
                                    <MenuItem value="">
                                      <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={100}>۱۰۰ متری</MenuItem>
                                    <MenuItem value={120}>۱۲۰ متری</MenuItem>
                                  </Select>
                                </FormControl>
                                <FormControl>
                                  <InputLabel id="demo-dialog-select-bedroom">
                                    اتاق خواب
                                  </InputLabel>
                                  <Select
                                    labelId="demo-dialog-select-bedroom"
                                    id="demo-dialog-select"
                                    value={this.state.bedroom}
                                    onChange={this.handleChange("bedroom")}
                                    input={<Input />}
                                    className="selection"
                                  >
                                    <MenuItem value="">
                                      <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={1}>یک خواب</MenuItem>
                                    <MenuItem value={2}>دو خواب</MenuItem>
                                    <MenuItem value={3}>سه خواب</MenuItem>
                                  </Select>
                                </FormControl>
                                <FormControl>
                                  <InputLabel id="demo-dialog-select-floor">
                                    مدل کف پوش
                                  </InputLabel>
                                  <Select
                                    labelId="demo-dialog-select-floor"
                                    id="demo-dialog-select"
                                    value={this.state.floor}
                                    onChange={this.handleChange("floor")}
                                    input={<Input />}
                                    className="selection"
                                  >
                                    <MenuItem value="">
                                      <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={"موکت"}>موکت</MenuItem>
                                    <MenuItem value={"سرامیک"}>سرامیک</MenuItem>
                                    <MenuItem value={"موزائیک"}>
                                      موزائیک
                                    </MenuItem>
                                    <MenuItem value={"پارکت"}>پارکت</MenuItem>
                                    <MenuItem value={"سیمان"}>سیمان</MenuItem>
                                  </Select>
                                </FormControl>
                              </form>
                              <div className="linkgenerate">
                                <Button
                                  variant="outlined"
                                  onClick={this.updateLink}
                                  color="primary"
                                >
                                  ساخت لینک
                                </Button>
                                <a
                                  style={{ display: this.state.displayBTN }}
                                  href={this.state.link}
                                  target={"_blank"}
                                  rel="noreferrer"
                                >
                                  <Button variant="contained" color="primary">
                                    مشاهده
                                  </Button>
                                </a>
                              </div>
                            </DialogContent>
                            <DialogActions>
                              <Button
                                onClick={this.handleCloseModal("OpenVtour")}
                                color="primary"
                              >
                                بستن
                              </Button>
                            </DialogActions>
                          </Dialog>
                          <a
                            href="http://bimplus.ir/9noor/"
                            target={"_blank"}
                            rel="noreferrer"
                            className="abtn"
                          >
                            <div variant="contained" color="primary">
                              <CustomCardRadio
                                img={shahrak}
                                Title={"مشاهده پروژه"}
                              ></CustomCardRadio>
                            </div>
                          </a>
                          <a
                            href="https://www.google.com/maps/@35.7260612,50.8694697,3a,75y,142.02h,83.64t/data=!3m8!1e1!3m6!1sAF1QipNR4FjepcSIPICM2JSS34D-nl-FZt-TSv6d1T2j!2e10!3e11!6shttps:%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipNR4FjepcSIPICM2JSS34D-nl-FZt-TSv6d1T2j%3Dw203-h100-k-no-pi-0-ya25.000011-ro-0-fo100!7i8704!8i4352"
                            target={"_blank"}
                            rel="noreferrer"
                            className="abtn"
                          >
                            <div variant="contained" color="primary">
                              <CustomCardRadio
                                img={shahrak}
                                Title={"مشاهده بر خط پروژه"}
                              ></CustomCardRadio>
                            </div>
                          </a>
                        </div>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion className="root_accordion">
                      <AccordionSummary>
                        <Typography
                          variant="h5"
                          color="inherit"
                          className="subtitle"
                        >
                          مالی
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="element">
                          <div
                            variant="contained"
                            color="primary"
                            // onClick={this.handleOpenModal("openMaps")}
                          >
                            <CustomCardRadio
                              img={finance}
                              Title={"دریافتی ها"}
                            ></CustomCardRadio>
                          </div>
                          <div
                            variant="contained"
                            color="primary"
                            // onClick={this.handleOpenModal("openMaps")}
                          >
                            <CustomCardRadio
                              img={finance}
                              Title={"پرداختی ها"}
                            ></CustomCardRadio>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>

                    <div className="fotter-back">
                      <Button
                        variant="contained"
                        color="primary"
                        className="footer-btn-back"
                        onClick={this.back}
                        style={{ bottom: "0px !important" }}
                      >
                        قبل
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Reports;
