import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import CustomCardRadio from "../../customCard/CustomCardRadio";
import Typography from "@material-ui/core/Typography";

import mooket from "../../../assets/svg icons/icons8-wallpaper-roll-100.svg";
import parket from "../../../assets/svg icons/icons8-wooden-floor-100-2.svg";
import ceramik from "../../../assets/svg icons/icons8-wooden-floor-100.svg";
import cimanet from "../../../assets/svg icons/icons8-cement-kiln-100.svg";
import mosaic from "../../../assets/svg/icons8-gantt-chart-100.svg";

import "../elements.css";

export class Floor extends Component {
  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const { values, onValueChange } = this.props;

    return (
      <React.Fragment>
        <div className="main">
          <div className="main-box">
            <div className="container space-2 space-md-3">
              <div className="steps">
                <div className="main-screen">
                  <br />
                  <Typography variant="h4" className="title">
                    کف پوش خود را انتخاب کنید
                  </Typography>
                  <br />
                  <div className="allItems">
                    <label>
                      <input
                        type="radio"
                        value="موزائیک"
                        onChange={onValueChange("floor")}
                        checked={values.option === "mozaek"}
                        onClick={this.continue}
                      ></input>
                      <CustomCardRadio
                        img={mosaic}
                        Title={"موزائیک"}
                      ></CustomCardRadio>
                    </label>
                    <label>
                      <input
                        type="radio"
                        value="موکت"
                        onChange={onValueChange("floor")}
                        checked={values.option === "mooket"}
                        onClick={this.continue}
                      ></input>
                      <CustomCardRadio
                        img={mooket}
                        Title={"موکت"}
                      ></CustomCardRadio>
                    </label>
                    <label>
                      <input
                        type="radio"
                        value="سرامیک"
                        onChange={onValueChange("floor")}
                        checked={values.option === "seramik"}
                        onClick={this.continue}
                      ></input>
                      <CustomCardRadio
                        img={ceramik}
                        Title={"سرامیک"}
                      ></CustomCardRadio>
                    </label>
                    <label>
                      <input
                        type="radio"
                        value="پارکت"
                        onChange={onValueChange("floor")}
                        checked={values.option === "parket"}
                        onClick={this.continue}
                      ></input>
                      <CustomCardRadio
                        img={parket}
                        Title={"پارکت"}
                      ></CustomCardRadio>
                    </label>
                    <label>
                      <input
                        type="radio"
                        value="سیمان"
                        onChange={onValueChange("floor")}
                        checked={values.option === "siman"}
                        onClick={this.continue}
                      ></input>
                      <CustomCardRadio
                        img={cimanet}
                        Title={"سیمان"}
                      ></CustomCardRadio>
                    </label>
                    <div className="fotter-back">
                      <Button
                        variant="contained"
                        color="primary"
                        className="footer-btn-back"
                        onClick={this.back}
                      >
                        قبل
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Floor;
