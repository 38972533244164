import React, { Component } from "react";

import "./CustomCardCheck.css";
import "./CustomCardCheck.scss";

class CustomCardCheck extends Component {
  render() {
    const { img, Title } = this.props;
    return (
      <div className="item">
        <div className="item-img">
          <img className="images" src={img} alt="mast dg" />
        </div>
        <p className="item-subtitle">{Title}</p>
      </div>
    );
  }
}

export default CustomCardCheck;
