import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import CustomCardRadio from "./customCard/CustomCardRadio";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Header from "./header/header";

import mapimg from "../assets/svg conf icons/icons8-floor-plan-100.svg";
// import analyseimg from "../assets/svg conf icons/icons8-google-analytics-logo-100.svg";
import reportsimg from "../assets/svg conf icons/icons8-pie-chart-report-100.svg";
// import finance from "../assets/svg/icons8-coins-100.svg";
import vtour from "../assets/svg/icons8-virtual-reality-100.svg";
import exteriorSiding from "../assets/svg/001-house.svg";

// import rizmetre from "../assets/employer/riz metreha.pdf";
import pelan100 from "../assets/employer/100/01.pdf";
import pelan120 from "../assets/employer/120/01.pdf";
import cad120 from "../assets/employer/120/CAD120m.rar";
import revit120 from "../assets/employer/120/ZA_ARCH_AMIRABADI_REV3_1401.11.24.rvt";
import pdf120 from "../assets/employer/120/PDF120m.rar";

import "./elements/elements.css";

export class HouseDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openReport: false,
      openMetre: false,
      openFinance: false,
      openMaps: false,
    };
  }

  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };
  handleOpenModal = (option) => () => {
    this.setState({
      [option]: true,
    });
  };
  handleCloseModal = (option) => () => {
    this.setState({
      [option]: false,
    });
  };

  componentDidMount() {
    // console.log(this.props.data.house_size);
    if (this.props.data.house_size === "100") {
      this.setState({
        pelan: pelan100,
      });
      switch (this.props.data.bedroom) {
        case "1":
          switch (this.props.data.floor) {
            case "موزائیک":
              this.setState({
                link: "http://Bimplus.ir/1mozaic",
              });
              break;
            case "موکت":
              this.setState({
                link: "http://Bimplus.ir/parket1",
              });
              break;
            case "سرامیک":
              this.setState({
                link: "http://Bimplus.ir/1Ceramic",
              });
              break;
            case "پارکت":
              this.setState({
                link: "http://Bimplus.ir/parket1",
              });
              break;
            case "سیمان":
              this.setState({
                link: "http://Bimplus.ir/1siman",
              });
              break;
            default:
              break;
          }
          break;
        case "2":
          switch (this.props.data.floor) {
            case "موزائیک":
              this.setState({
                link: "http://Bimplus.ir/2mozaic",
              });
              break;
            case "موکت":
              this.setState({
                link: "http://Bimplus.ir/parket2",
              });
              break;
            case "سرامیک":
              this.setState({
                link: "http://Bimplus.ir/2ceramic",
              });
              break;
            case "پارکت":
              this.setState({
                link: "http://Bimplus.ir/parket2",
              });
              break;
            case "سیمان":
              this.setState({
                link: "http://Bimplus.ir/2siman",
              });
              break;
            default:
              break;
          }
          break;
        case "3":
          switch (this.props.data.floor) {
            case "موزائیک":
              this.setState({
                link: "http://Bimplus.ir/3mozic",
              });
              break;
            case "موکت":
              this.setState({
                link: "http://Bimplus.ir/parket3",
              });
              break;
            case "سرامیک":
              this.setState({
                link: "http://Bimplus.ir/3Ceramic",
              });
              break;
            case "پارکت":
              this.setState({
                link: "http://Bimplus.ir/parket3",
              });
              break;
            case "سیمان":
              this.setState({
                link: "http://Bimplus.ir/3siman",
              });
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
    } else {
      this.setState({
        pelan: pelan120,
      });
      switch (this.props.data.bedroom) {
        case "1":
          switch (this.props.data.floor) {
            case "موزائیک":
              this.setState({
                link: "http://Bimplus.ir/1mozaic",
              });
              break;
            case "موکت":
              this.setState({
                link: "http://Bimplus.ir/parket1",
              });
              break;
            case "سرامیک":
              this.setState({
                link: "http://Bimplus.ir/1Ceramic",
              });
              break;
            case "پارکت":
              this.setState({
                link: "http://Bimplus.ir/parket1",
              });
              break;
            case "سیمان":
              this.setState({
                link: "http://Bimplus.ir/1siman",
              });
              break;
            default:
              break;
          }
          break;
        case "2":
          switch (this.props.data.floor) {
            case "موزائیک":
              this.setState({
                link: "http://Bimplus.ir/2mozaic",
              });
              break;
            case "موکت":
              this.setState({
                link: "http://Bimplus.ir/parket2",
              });
              break;
            case "سرامیک":
              this.setState({
                link: "http://Bimplus.ir/2ceramic",
              });
              break;
            case "پارکت":
              this.setState({
                link: "http://Bimplus.ir/parket2",
              });
              break;
            case "سیمان":
              this.setState({
                link: "http://Bimplus.ir/2siman",
              });
              break;
            default:
              break;
          }
          break;
        case "3":
          switch (this.props.data.floor) {
            case "موزائیک":
              this.setState({
                link: "http://Bimplus.ir/3mozic",
              });
              break;
            case "موکت":
              this.setState({
                link: "http://Bimplus.ir/parket3",
              });
              break;
            case "سرامیک":
              this.setState({
                link: "http://Bimplus.ir/3Ceramic",
              });
              break;
            case "پارکت":
              this.setState({
                link: "http://Bimplus.ir/parket3",
              });
              break;
            case "سیمان":
              this.setState({
                link: "http://Bimplus.ir/3siman",
              });
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
    }
  }

  render() {
    const { onPagenumberChange } = this.props;

    return (
      <React.Fragment>
        <Header Title="سیستم انتخاب خانه"></Header>
        <div className="main">
          <div className="main-box">
            <div className="container space-2 space-md-3">
              <div className="steps">
                <div className="main-screen">
                  <br />
                  <Typography variant="h4" className="title">
                    اطلاعات خانه
                  </Typography>
                  <br />
                  <div className="details">
                    <div
                      variant="contained"
                      color="primary"
                      className=" "
                      onClick={this.handleOpenModal("openMaps")}
                    >
                      <CustomCardRadio
                        img={mapimg}
                        Title={"نقشه‌ها"}
                      ></CustomCardRadio>
                    </div>
                    <Dialog
                      open={this.state.openMaps}
                      onClose={this.handleCloseModal("openMaps")}
                      aria-labelledby="form-dialog-title"
                      className="dialog_confirm"
                    >
                      <DialogTitle id="form-dialog-title">
                        مشاهده نقشه های خانه
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          دانلود اطلاعات و نقشه‌ها
                        </DialogContentText>
                        <div id="pelansmap">
                          <div className="itemsdl">
                            <div
                              variant="contained"
                              color="primary"
                              className="ConfirmBtnContractor"
                            >
                              <CustomCardRadio
                                img={exteriorSiding}
                                Title={"پلان معماری"}
                              ></CustomCardRadio>
                            </div>
                            <Button
                              variant="outlined"
                              color="primary"
                              className="info_btns"
                              href={this.state.pelan}
                            >
                              دانلود فایل پلان
                            </Button>
                          </div>
                          <div className="itemsdl">
                            <div
                              variant="contained"
                              color="primary"
                              className="ConfirmBtnContractor"
                            >
                              <CustomCardRadio
                                img={exteriorSiding}
                                Title={"نقشه های اجرایی"}
                              ></CustomCardRadio>
                            </div>
                            <Button
                              variant="outlined"
                              color="primary"
                              className="info_btns"
                              href={cad120}
                            >
                              دانلود فایل Autocad
                            </Button>
                            <Button
                              variant="outlined"
                              color="primary"
                              className="info_btns"
                              href={revit120}
                            >
                              دانلود فایل Revit
                            </Button>
                            <Button
                              variant="outlined"
                              color="primary"
                              className="info_btns"
                              href={pdf120}
                            >
                              دانلود فایل PDF
                            </Button>
                          </div>
                        </div>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={this.handleCloseModal("openMaps")}
                          color="primary"
                        >
                          بستن
                        </Button>
                      </DialogActions>
                    </Dialog>
                    <a
                      href={this.state.link}
                      target={"_blank"}
                      rel="noreferrer"
                      className="abtn "
                    >
                      <div variant="contained" color="primary" className=" ">
                        <CustomCardRadio
                          img={vtour}
                          Title={"تور مجازی خانه"}
                        ></CustomCardRadio>
                      </div>
                    </a>
                    {/* <div
                      variant="contained"
                      color="primary"
                      className=" "
                      onClick={this.handleOpenModal("openMetre")}
                    >
                      <CustomCardRadio
                        img={analyseimg}
                        Title={"متره"}
                      ></CustomCardRadio>
                    </div>
                    <Dialog
                      open={this.state.openMetre}
                      onClose={this.handleCloseModal("openMetre")}
                      aria-labelledby="form-dialog-title"
                      className="dialog_confirm"
                    >
                      <DialogTitle id="form-dialog-title">
                        مشاهده متره ها
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                        </DialogContentText>
                        <Button
                          variant="outlined"
                          color="primary"
                          className="info_btns"
                          href={rizmetre}
                        >
                          دانلود متره ها
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          className="info_btns"
                          // href={fehrestbaha}
                          onClick={onPagenumberChange("page", "reports")}
                        >
                          مشاهده متره
                        </Button>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={this.handleCloseModal("openMetre")}
                          color="primary"
                        >
                          بستن
                        </Button>
                      </DialogActions>
                    </Dialog> */}
                    <div
                      variant="contained"
                      color="primary"
                      className=" "
                      onClick={this.handleOpenModal("openReport")}
                    >
                      <CustomCardRadio
                        img={reportsimg}
                        Title={"گزارش پیشرفت"}
                      ></CustomCardRadio>
                    </div>
                    <Dialog
                      open={this.state.openReport}
                      onClose={this.handleCloseModal("openReport")}
                      aria-labelledby="form-dialog-title"
                      className="dialog_confirm"
                    >
                      <DialogTitle id="form-dialog-title">
                        مشاهده گزارشات
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          {/* دانلود اطلاعات و نقشه‌ها */}
                        </DialogContentText>
                        <Button
                          variant="outlined"
                          color="primary"
                          className="info_btns"
                          onClick={onPagenumberChange("page", "soratvaziat")}
                        >
                          عملکردی
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          className="info_btns"
                          onClick={onPagenumberChange("page", "nemodar")}
                        >
                          نمودار
                        </Button>
                        <a
                          variant="outlined"
                          color="primary"
                          className="info_btns MuiButtonBase-root MuiButton-root MuiButton-outlined info_btns MuiButton-outlinedPrimary"
                          // onClick={onPagenumberChange("page", "imagereport")}
                          href="http://bimplus.ir/9noor/"
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          گزارش تصویری
                        </a>
                        <Button
                          variant="outlined"
                          color="primary"
                          className="info_btns"
                          onClick={onPagenumberChange("page", "ahjam")}
                        >
                          فیزیکی
                        </Button>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={this.handleCloseModal("openReport")}
                          color="primary"
                        >
                          بستن
                        </Button>
                      </DialogActions>
                    </Dialog>

                    <div className="fotter-back">
                      <Button
                        variant="contained"
                        color="primary"
                        className="footer-btn-back"
                        onClick={this.back}
                      >
                        قبل
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default HouseDetails;
