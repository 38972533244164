import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import CustomCardCheck from "../../customCard/CustomCardCheck";
import Typography from "@material-ui/core/Typography";

// import cabinets from "../../../assets/svg icons/icons8-pantry-100-2.svg";
import comods from "../../../assets/svg icons/icons8-pantry-100.svg";
import cabinets from "../../../assets/svg/005-kitchen.svg";

import "../elements.css";

export class Kabinets extends Component {
  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const { handleCheck } = this.props;

    return (
      <React.Fragment>
        <div className="main">
          <div className="main-box">
            <div className="container space-2 space-md-3">
              <div className="steps">
                <div className="main-screen">
                  <br />
                  <Typography variant="h4" className="title">
                    امکانات دیواری را انتخاب کنید
                  </Typography>
                  <br />
                  <div className="allItems">
                    <label className="">
                      <input
                        type="checkbox"
                        value="کابینت"
                        onChange={handleCheck("kabinets", 1)}
                      ></input>
                      <CustomCardCheck
                        img={cabinets}
                        Title={"کابینت"}
                      ></CustomCardCheck>
                    </label>
                    <label className="">
                      <input
                        type="checkbox"
                        value="کمد دیواری"
                        onChange={handleCheck("kabinets", 2)}
                      ></input>
                      <CustomCardCheck
                        img={comods}
                        Title={"کمد دیواری"}
                      ></CustomCardCheck>
                    </label>
                    <div className="fotter-back">
                      <Button
                        variant="contained"
                        color="primary"
                        className="footer-btn-back"
                        onClick={this.back}
                      >
                        قبل
                      </Button>
                    </div>
                    <div className="fotter">
                      <Button
                        variant="contained"
                        color="primary"
                        className="footer-btn-next"
                        onClick={this.continue}
                        // disabled={this.props.uniqrooms.length === 0}
                      >
                        بعد
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Kabinets;
