import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Header from "../../header/header";
// import SelectItems from "./Select";

import "../elements.css";
import sazeimg from "../../../assets/svg conf icons/icons8-pavilion-100.svg";
import seftimg from "../../../assets/svg conf icons/icons8-steel-i-beam-100.svg";
import nazokimg from "../../../assets/svg conf icons/icons8-paint-roller-100.svg";

import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Saze1 from "../../../assets/modals/saze1.jpg";
import Saze2 from "../../../assets/modals/saze2.jpg";
import Seft1 from "../../../assets/modals/seft1.jpg";
import Seft2 from "../../../assets/modals/seft2.jpg";
import Nazok1 from "../../../assets/modals/nazok1.jpg";
import Nazok2 from "../../../assets/modals/nazok2.jpg";

import SazeDownload from "../../../assets/modals/saze.zip";
import SeftDownload from "../../../assets/modals/seftkari.zip";
import NazokDownload from "../../../assets/modals/nazokkari.zip";

import CustomCardRadio from "../../customCard/CustomCardRadio";

export class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      estimated: null,
      openCalculator: false,
      openPlan: false,
      openBuilding: false,
      openHardening: false,
      openJoinery: false,
      openInfo: false,
      openTashilat: false,
    };
  }

  continue = (e) => {
    e.preventDefault();
    // console.log(this.props);
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  renderBackBtn() {
    return (
      <div className="fotter-back">
        <Button
          variant="contained"
          color="primary"
          className="footer-btn-back"
          onClick={this.back}
        >
          قبل
        </Button>
      </div>
    );
  }

  handleOpenModal = (option) => () => {
    this.setState({
      [option]: true,
    });
  };
  handleCloseModal = (option) => () => {
    this.setState({
      [option]: false,
    });
  };

  render() {
    // const { onValueChange, values } = this.props;
    return (
      <React.Fragment>
        <Header Title="سیستم انتخاب خانه"></Header>
        <div className="main">
          <div className="main-box">
            <div className="container space-2 space-md-3">
              <div className="steps">
                <div className="main-screen">
                  <br />
                  <Typography variant="h4" className="title">
                    اطلاعات متره ها
                  </Typography>
                  <br />
                  <div className="details">
                    <div
                      variant="contained"
                      color="primary"
                      className="ConfirmBtnContractor"
                      onClick={this.handleOpenModal("openBuilding")}
                    >
                      <CustomCardRadio
                        img={sazeimg}
                        Title={"سازه"}
                      ></CustomCardRadio>
                    </div>
                    <div
                      variant="contained"
                      color="primary"
                      className="ConfirmBtnContractor"
                      onClick={this.handleOpenModal("openHardening")}
                    >
                      <CustomCardRadio
                        img={seftimg}
                        Title={"سفت کاری"}
                      ></CustomCardRadio>
                    </div>
                    <div
                      variant="contained"
                      color="primary"
                      className="ConfirmBtnContractor"
                      onClick={this.handleOpenModal("openJoinery")}
                    >
                      <CustomCardRadio
                        img={nazokimg}
                        Title={"نازک کاری"}
                      ></CustomCardRadio>
                    </div>
                    <Dialog
                      open={this.state.openBuilding}
                      onClose={this.handleCloseModal("openBuilding")}
                      aria-labelledby="form-dialog-title"
                      className="dialog_confirm"
                    >
                      <DialogTitle id="form-dialog-title">سازه</DialogTitle>
                      <DialogContent>
                        <DialogContentText>تصاویر سازه</DialogContentText>
                        <div className="image_hodler">
                          <img className="modalimage" src={Saze1} alt="Saze1" />
                          <img className="modalimage" src={Saze2} alt="Saze1" />
                        </div>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={this.handleCloseModal("openBuilding")}
                          color="primary"
                        >
                          بستن
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          href={SazeDownload}
                        >
                          دانلود تصاویر
                        </Button>
                      </DialogActions>
                    </Dialog>
                    <Dialog
                      open={this.state.openHardening}
                      onClose={this.handleCloseModal("openHardening")}
                      aria-labelledby="form-dialog-title"
                      className="dialog_confirm"
                    >
                      <DialogTitle id="form-dialog-title">سفت کاری</DialogTitle>
                      <DialogContent>
                        <DialogContentText>تصاویر سفت کاری</DialogContentText>
                        <div className="image_hodler">
                          <img className="modalimage" src={Seft1} alt="Saze1" />
                          <img className="modalimage" src={Seft2} alt="Saze1" />
                        </div>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={this.handleCloseModal("openHardening")}
                          color="primary"
                        >
                          بستن
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          href={SeftDownload}
                        >
                          دانلود تصاویر
                        </Button>
                      </DialogActions>
                    </Dialog>
                    <Dialog
                      open={this.state.openJoinery}
                      onClose={this.handleCloseModal("openJoinery")}
                      aria-labelledby="form-dialog-title"
                      className="dialog_confirm"
                    >
                      <DialogTitle id="form-dialog-title">
                        نازک کاری
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText>تصاویر نازک کاری</DialogContentText>
                        <div className="image_hodler">
                          <img
                            className="modalimage"
                            src={Nazok1}
                            alt="Saze1"
                          />
                          <img
                            className="modalimage"
                            src={Nazok2}
                            alt="Saze1"
                          />
                        </div>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={this.handleCloseModal("openJoinery")}
                          color="primary"
                        >
                          بستن
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          href={NazokDownload}
                        >
                          دانلود تصاویر
                        </Button>
                      </DialogActions>
                    </Dialog>
                    <div className="fotter-back">
                      <Button
                        variant="contained"
                        color="primary"
                        className="footer-btn-back"
                        onClick={this.back}
                      >
                        قبل
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Reports;
