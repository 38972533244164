import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import CustomCardRadio from "../../customCard/CustomCardRadio";
import Typography from "@material-ui/core/Typography";

import "../elements.css";
import shahrak from "../../../assets/svg/icons8-city-100.svg";
import user from "../../../assets/svg icons/icons8-account-100.svg";

export class SelectHouse extends Component {
  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const { values, onValueChange } = this.props;

    return (
      <React.Fragment>
        <div className="main">
          <div className="main-box">
            <div className="container space-2 space-md-3">
              <div className="steps">
                <div className="main-screen">
                  <br />
                  <Typography
                    variant="h4"
                    className="title font-weight-bold Title-elements"
                  >
                    واحد خود را در پروژه انتخاب کنید
                  </Typography>
                  <br />
                  <div className="allItems">
                    <a
                      href="http://bimplus.ir/9noor/"
                      target={"_blank"}
                      rel="noreferrer"
                      className="abtn"
                    >
                      <div
                        variant="contained"
                        color="primary"
                        className="ConfirmBtnContractor"
                      >
                        <CustomCardRadio
                          img={shahrak}
                          Title={"انتخاب واحد در پروژه"}
                        ></CustomCardRadio>
                      </div>
                    </a>
                    <label>
                      <input
                        type="radio"
                        value="True"
                        onChange={onValueChange("personalinfo")}
                        checked={values.option === "personalinfo"}
                        onClick={this.continue}
                      ></input>
                      <CustomCardRadio
                        img={user}
                        Title={"تکمیل مشخصات فردی"}
                      ></CustomCardRadio>
                    </label>

                    <div className="fotter-next">
                      <Button
                        variant="contained"
                        color="primary"
                        className="footer-btn-back"
                        onClick={this.back}
                      >
                        قبل
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        className="footer-btn-next"
                        onClick={this.continue}
                      >
                        بعدی
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SelectHouse;
