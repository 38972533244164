import React, { Component } from "react";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import Header from "./header/header";
import CustomCardRadio from "./customCard/CustomCardRadio";

import user from "../assets/svg icons/icons8-account-100.svg";
import contractor from "../assets/svg icons/icons8-engineer-100_1.svg";

import "./SelectedForm.css";

export class SelectedForm extends Component {
  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  render() {
    const { values, onValueChange } = this.props;

    return (
      <MuiThemeProvider>
        <React.Fragment>
          <Header Title="سیستم انتخاب خانه"></Header>
          <div className="main">
            <div className="main-box">
              <div className="container space-2 space-md-3">
                <div className="steps">
                  <div className="main-screen">
                    <br />
                    {/* <Typography variant="h4" className="title font-weight-bold">
                      کار شما از اینجا شروع میشود
                    </Typography> */}
                    <Typography
                      variant="h5"
                      className="subTitle font-weight-light"
                    >
                      نوع کاربری{" "}
                    </Typography>
                    <div className="allItems">
                      <label>
                        <input
                          type="radio"
                          value="employer"
                          onChange={onValueChange("category")}
                          checked={values.category === "employer"}
                          onClick={this.continue}
                        ></input>
                        <CustomCardRadio
                          img={user}
                          Title={"کارفرما"}
                        ></CustomCardRadio>
                      </label>
                      <label>
                        <input
                          type="radio"
                          value="contractor"
                          onChange={onValueChange("category")}
                          checked={values.category === "contractor"}
                          onClick={this.continue}
                        ></input>
                        <CustomCardRadio
                          img={contractor}
                          Title={"پیمانکار"}
                        ></CustomCardRadio>
                      </label>
                      <label>
                        <input
                          type="radio"
                          value="buyer"
                          onChange={onValueChange("category")}
                          checked={values.category === "buyer"}
                          onClick={this.continue}
                        ></input>
                        <CustomCardRadio
                          img={user}
                          Title={"متقاضی"}
                        ></CustomCardRadio>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      </MuiThemeProvider>
    );
  }
}

export default SelectedForm;
