import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import CustomCardCheck from "../../customCard/CustomCardCheck";
import Typography from "@material-ui/core/Typography";

import watercooler from "../../../assets/svg icons/icons8-water-heater-100.svg";
import packagehiter from "../../../assets/svg icons/icons8-heating-radiator-100.svg";
import radiator from "../../../assets/svg icons/icons8-radiator-100.svg";
import bokhari from "../../../assets/svg/room-heater-black.svg";
import abgarmkon from "../../../assets/svg/abgarmkon.svg";
import wc from "../../../assets/svg/icons8-wc-100.svg";

import "../elements.css";

export class Cooling extends Component {
  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const { handleCheck } = this.props;

    return (
      <React.Fragment>
        <div className="main">
          <div className="main-box">
            <div className="container space-2 space-md-3">
              <div className="steps">
                <div className="main-screen">
                  <br />
                  <Typography variant="h4" className="title">
                    سیستم سرمایش و گرمایش خود را مشخص کنید
                  </Typography>
                  <br />
                  <div className="allItems">
                    <label className="lable-items">
                      <input
                        type="checkbox"
                        value="کولر آبی"
                        onChange={handleCheck("cooling", 1)}
                      ></input>
                      <CustomCardCheck
                        img={watercooler}
                        Title={"کولر آبی"}
                      ></CustomCardCheck>
                    </label>
                    <label className="lable-items">
                      <input
                        type="checkbox"
                        value="پکیج"
                        onChange={handleCheck("cooling", 2)}
                      ></input>
                      <CustomCardCheck
                        img={packagehiter}
                        Title={"پکیج"}
                      ></CustomCardCheck>
                    </label>
                    <label className="lable-items">
                      <input
                        type="checkbox"
                        value="رادیاتور"
                        onChange={handleCheck("cooling", 3)}
                      ></input>
                      <CustomCardCheck
                        img={radiator}
                        Title={"رادیاتور"}
                      ></CustomCardCheck>
                    </label>
                    <label className="lable-items">
                      <input
                        type="checkbox"
                        value="بخاری"
                        onChange={handleCheck("cooling", 4)}
                      ></input>
                      <CustomCardCheck
                        img={bokhari}
                        Title={"بخاری"}
                      ></CustomCardCheck>
                    </label>
                    <label className="lable-items">
                      <input
                        type="checkbox"
                        value="آبگرمکن"
                        onChange={handleCheck("cooling", 5)}
                      ></input>
                      <CustomCardCheck
                        img={abgarmkon}
                        Title={"آبگرمکن"}
                      ></CustomCardCheck>
                    </label>
                    <label className="lable-items">
                      <input
                        type="checkbox"
                        value="توالت فرنگی"
                        onChange={handleCheck("cooling", 6)}
                      ></input>
                      <CustomCardCheck
                        img={wc}
                        Title={"توالت فرنگی"}
                      ></CustomCardCheck>
                    </label>
                    <div className="fotter-back">
                      <Button
                        variant="contained"
                        color="primary"
                        className="footer-btn-back"
                        onClick={this.back}
                      >
                        قبل
                      </Button>
                    </div>
                    <div className="fotter">
                      <Button
                        variant="contained"
                        color="primary"
                        className="footer-btn-next"
                        onClick={this.continue}
                        // disabled={this.state.disablebtn}
                      >
                        بعد
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Cooling;
