import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import CustomCardRadio from "../../customCard/CustomCardRadio";
import Typography from "@material-ui/core/Typography";

import "../elements.css";

export class HouseSize extends Component {
  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const { values, onValueChange, complete, partial } = this.props;

    return (
      <React.Fragment>
        <div className="main">
          <div className="main-box">
            <div className="container space-2 space-md-3">
              <div className="steps">
                <div className="main-screen">
                  <br />
                  <Typography
                    variant="h4"
                    className="title font-weight-bold Title-elements"
                  >
                    متراژ خانه را انتخاب کنید
                  </Typography>
                  <br />
                  <div className="allItems">
                    <label>
                      <input
                        type="radio"
                        value="100"
                        onChange={onValueChange("house_size")}
                        checked={values.option === "100"}
                        onClick={this.continue}
                      ></input>
                      <CustomCardRadio
                        img={complete}
                        Title={"۱۰۰ متری"}
                      ></CustomCardRadio>
                    </label>
                    <label>
                      <input
                        type="radio"
                        value="120"
                        onChange={onValueChange("house_size")}
                        checked={values.option === "120"}
                        onClick={this.continue}
                      ></input>
                      <CustomCardRadio
                        img={partial}
                        Title={"۱۲۰ متری"}
                      ></CustomCardRadio>
                    </label>
                    <div className="fotter-back">
                      <Button
                        variant="contained"
                        color="primary"
                        className="footer-btn-back"
                        onClick={this.back}
                      >
                        قبل
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default HouseSize;
