import React, { Component } from 'react'

import './CustomCardRadio.css';
import './CustomCardRadio.scss';

class CustomCardRadio extends Component {

    render() {    
        const { img, Title } = this.props;
        return (
            <div className = "item">
                <div className="item-img">
                    <img className="images" src={img} alt="mast dg" />
                </div>
                <p className="item-subtitle ">{Title}</p>
            </div>
        )
    }
}

export default CustomCardRadio