import React, { Component } from "react";

import HouseSize from "./HouseSize";
import Floor from "./Floor";
import Cooling from "./Cooling";
import Kabinets from "./Kabinets";
import Bedroom from "./Bedroom";
import Login from "./Login";
import SelectHouse from "./SelectHouse";
import PersonalInfo from "./PersonalInfo";

import house from "../../../assets/svg/004-basement.svg";

import "../elements.css";
import Sidebar from "../../Sidebar";
import Header from "../../header/header";

export class Buyer extends Component {
  constructor() {
    super();
    this.state = {
      inStep: 1,
    };
  }

  inNextStep = () => {
    const { inStep } = this.state;
    this.setState({
      inStep: inStep + 1,
    });
  };

  prevStep = () => {
    const { inStep } = this.state;
    this.setState({
      inStep: inStep - 1,
    });
  };

  //Proced to Prev step
  nextStep = () => {
    this.props.nextStep();
  };

  //Proced to Prev step
  inPrevStep = () => {
    this.props.prevStep();
  };

  renderSidebar(inStep) {
    return (
      <Sidebar
        stepper={inStep}
        stepperList={[
          "کاربر",
          "ورود",
          "انتخاب واحد در پروژه",
          "متراژ",
          "تعداد خواب",
          "کف پوش",
          "سیستم سرمایش/گرمایش",
          "امکانات دیواری",
        ]}
      ></Sidebar>
    );
  }

  renderSwitch(inStep, values, onValueChange, handleCheck) {
    switch (inStep) {
      case 1:
        return (
          <Login
            nextStep={this.inNextStep}
            prevStep={this.inPrevStep}
            onValueChange={this.onValueChange}
            values={values}
          ></Login>
        );
      case 2:
        return (
          <SelectHouse
            nextStep={this.inNextStep}
            prevStep={this.prevStep}
            onValueChange={onValueChange}
            values={values}
          ></SelectHouse>
        );
      case 3:
        if (this.props.data.personalinfo === "True") {
          return (
            <PersonalInfo
              nextStep={this.inNextStep}
              prevStep={this.prevStep}
              onValueChange={onValueChange}
              values={values}
              complete={house}
              partial={house}
            ></PersonalInfo>
          );
        } else {
          return (
            <HouseSize
              nextStep={this.inNextStep}
              prevStep={this.prevStep}
              onValueChange={onValueChange}
              values={values}
              complete={house}
              partial={house}
            ></HouseSize>
          );
        }
      case 4:
        return (
          <Bedroom
            nextStep={this.inNextStep}
            prevStep={this.prevStep}
            onValueChange={onValueChange}
            values={values}
          ></Bedroom>
        );
      case 5:
        return (
          <Floor
            nextStep={this.inNextStep}
            prevStep={this.prevStep}
            onValueChange={onValueChange}
            values={values}
          ></Floor>
        );
      case 6:
        return (
          <Cooling
            nextStep={this.inNextStep}
            prevStep={this.prevStep}
            onValueChange={onValueChange}
            handleCheck={handleCheck}
            values={values}
            uniqcoolig={this.props.uniqcoolig}
          ></Cooling>
        );
      case 7:
        return (
          <Kabinets
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            onValueChange={onValueChange}
            handleCheck={handleCheck}
            values={values}
            uniqkabinets={this.props.uniqkabinets}
          ></Kabinets>
        );
      default:
        break;
    }
  }

  render() {
    const { inStep } = this.state;
    const { option, onValueChange, handleCheck } = this.props;
    const values = { option, handleCheck };

    return (
      <React.Fragment>
        <Header Title="سیستم انتخاب خانه" User="کاربر :‌ متقاضی"></Header>
        {this.renderSidebar(inStep)}
        {this.renderSwitch(inStep, values, onValueChange, handleCheck)}
      </React.Fragment>
    );
  }
}

export default Buyer;
