import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Header from "./header/header";
// import SelectItems from "./Select";

import "./elements/elements.css";
import { Typography } from "@material-ui/core";

import "./ConfirmContractor.css";

import img from "../assets/005-architect.svg";
import allmapsimg from "../assets/svg conf icons/icons8-map-editing-100.svg";
import analyseimg from "../assets/svg conf icons/icons8-google-analytics-logo-100.svg";
import mapimg from "../assets/svg conf icons/icons8-floor-plan-100.svg";
import sazeimg from "../assets/svg conf icons/icons8-pavilion-100.svg";
import seftimg from "../assets/svg conf icons/icons8-steel-i-beam-100.svg";
import nazokimg from "../assets/svg conf icons/icons8-paint-roller-100.svg";
import infoimg from "../assets/svg conf icons/icons8-books-100.svg";
import reportsimg from "../assets/svg conf icons/icons8-pie-chart-report-100.svg";

import axios from "axios";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import BuidlingImage from "../assets/modals/map.jpg";
import Saze1 from "../assets/modals/saze1.jpg";
import Saze2 from "../assets/modals/saze2.jpg";
import Seft1 from "../assets/modals/seft1.jpg";
import Seft2 from "../assets/modals/seft2.jpg";
import Nazok1 from "../assets/modals/nazok1.jpg";
import Nazok2 from "../assets/modals/nazok2.jpg";

import Allmaps from "../assets/modals/allmaps.rar";
import Analyse from "../assets/modals/analyse.xlsx";
import Mapfile from "../assets/modals/map.pdf";
import Ten from "../assets/modals/10-10.rar";
import Twoelve from "../assets/modals/10-12.rar";
import Site from "../assets/modals/site.rar";
import SazeDownload from "../assets/modals/saze.zip";
import SeftDownload from "../assets/modals/seftkari.zip";
import NazokDownload from "../assets/modals/nazokkari.zip";
import CustomCardRadio from "./customCard/CustomCardRadio";

export class ConfirmContractor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      estimated: null,
      openCalculator: false,
      openPlan: false,
      openBuilding: false,
      openHardening: false,
      openJoinery: false,
      openInfo: false,
      openTashilat: false,
      bime: 0,
      avarez: 0,
      amadesazi: 0,
      nezam: 0,
      total: 0,
      price: 0,
      tashilatType: "",
      tashilatPrice: 0,
    };
  }

  continue = (e) => {
    e.preventDefault();
    // console.log(this.props);
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  renderBackBtn() {
    return (
      <div className="fotter-back">
        <Button
          variant="contained"
          color="primary"
          className="footer-btn-back"
          onClick={this.back}
        >
          Back
        </Button>
      </div>
    );
  }

  componentDidMount = () => {
    this.props.datas.push(this.props.data);
    for (let i = 0; i < this.props.datas.length; i++) {
      this.setState({
        items: this.props.data.items.push(
          <option value={i}> {this.props.datas[i].category} </option>
        ),
      });
    }
    axios
      .get(`https://jsonplaceholder.typicode.com/users`, {
        category: this.props.data.category,
        options: this.props.data.uniqOptions,
        materials: this.props.data.materials,
        size: this.props.data.size,
      })
      .then((res) => {
        this.setState({
          estimated: res.data,
        });
      });
  };

  reset = () => {
    this.props.reset();
  };

  handleOpenModal = (option) => () => {
    this.setState({
      [option]: true,
    });
  };
  handleCloseModal = (option) => () => {
    this.setState({
      [option]: false,
    });
  };

  handleClose = () => {
    let totalprice =
      this.state.bime +
      this.state.avarez +
      this.state.amadesazi +
      this.state.nezam +
      this.state.total;
    this.setState({
      price: totalprice,
      openCalculator: false,
    });
  };

  renderContractorBtn() {
    return (
      <div>
        <div
          variant="contained"
          color="primary"
          className="ConfirmBtnContractor"
          onClick={this.handleOpenModal("openInfo")}
        >
          <CustomCardRadio
            img={infoimg}
            Title={"دانلود اطلاعات"}
          ></CustomCardRadio>
        </div>
        <Dialog
          open={this.state.openInfo}
          onClose={this.handleCloseModal("openInfo")}
          aria-labelledby="form-dialog-title"
          className="dialog_confirm"
        >
          <DialogTitle id="form-dialog-title">دانلود اطلاعات</DialogTitle>
          <DialogContent>
            <DialogContentText>دانلود اطلاعات و نقشه‌ها</DialogContentText>
            <Button
              variant="outlined"
              color="primary"
              className="info_btns"
              href={Ten}
            >
              دانلود ۱۰*۱۰
            </Button>
            <Button
              variant="outlined"
              color="primary"
              className="info_btns"
              href={Twoelve}
            >
              دانلود ۱۰*۱۲
            </Button>
            <Button
              variant="outlined"
              color="primary"
              className="info_btns"
              href={Site}
            >
              دانلود سایت
            </Button>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseModal("openInfo")} color="primary">
              بستن
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  onValueChange = (option) => (event) => {
    this.setState({
      [option]: Number(event.target.value),
    });
  };

  render() {
    // const { onValueChange, values } = this.props;
    return (
      <div>
        <Header Title="سیستم انتخاب خانه"></Header>
        <div className="invoice">
          <div className="headLine items">
            <img className="icon" src={img} alt="mast dg" />
            <Typography variant="h6" className="head-title">
              مرحله پایانی
            </Typography>
          </div>
          <div className="ConfirmBTNS">
            <a
              variant="contained"
              color="primary"
              className="ConfirmBtnContractor"
              href={Allmaps}
            >
              <CustomCardRadio
                img={allmapsimg}
                Title={"دانلود تمام نقشه‌ها"}
              ></CustomCardRadio>
            </a>
            <a
              variant="contained"
              color="primary"
              className="ConfirmBtnContractor"
              href={Analyse}
            >
              <CustomCardRadio
                img={analyseimg}
                Title={"دانلود متره و برآورد"}
              ></CustomCardRadio>
            </a>
            <div
              variant="contained"
              color="primary"
              className="ConfirmBtnContractor"
              onClick={this.handleOpenModal("openPlan")}
            >
              <CustomCardRadio img={mapimg} Title={"نقشه"}></CustomCardRadio>
            </div>
            <div
              variant="contained"
              color="primary"
              className="ConfirmBtnContractor"
              onClick={this.handleOpenModal("openBuilding")}
            >
              <CustomCardRadio img={sazeimg} Title={"سازه"}></CustomCardRadio>
            </div>
            <div
              variant="contained"
              color="primary"
              className="ConfirmBtnContractor"
              onClick={this.handleOpenModal("openHardening")}
            >
              <CustomCardRadio
                img={seftimg}
                Title={"سفت کاری"}
              ></CustomCardRadio>
            </div>
            <div
              variant="contained"
              color="primary"
              className="ConfirmBtnContractor"
              onClick={this.handleOpenModal("openJoinery")}
            >
              <CustomCardRadio
                img={nazokimg}
                Title={"نازک کاری"}
              ></CustomCardRadio>
            </div>
            {this.renderContractorBtn()}
            <a
              variant="contained"
              color="primary"
              className="ConfirmBtnContractor"
              href={Analyse}
            >
              <CustomCardRadio
                img={reportsimg}
                Title={"مشاهده گزارشات"}
              ></CustomCardRadio>
            </a>
          </div>
          <div className="Estimate items">
            {/* <Typography variant="h5">پیش بینی هزینه‌ها</Typography> */}
            <div class="price-container">
              <Button
                variant="contained"
                color="primary"
                className="footer-btns"
                onClick={this.handleOpenModal("openCalculator")}
              >
                محاسبه هزینه ها
              </Button>
              <Dialog
                open={this.state.openCalculator}
                onClose={this.handleCloseModal("openCalculator")}
                aria-labelledby="form-dialog-title"
                className="dialog_confirm dialog_calculator"
              >
                <DialogTitle id="form-dialog-title">محاسبه گر</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    لطفا مقادیر زیر را برای محاسبه هزینه وارد کنید
                  </DialogContentText>
                  <TextField
                    fullWidth
                    id="outlined-number"
                    label="بیمه مالیات "
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    onChange={this.onValueChange("bime")}
                  />
                  <TextField
                    fullWidth
                    id="outlined-number"
                    label="عوارض "
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    onChange={this.onValueChange("avarez")}
                  />
                  <TextField
                    fullWidth
                    id="outlined-number"
                    label="اماده سازی "
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    onChange={this.onValueChange("amadesazi")}
                  />
                  <TextField
                    fullWidth
                    id="outlined-number"
                    label="نظام مهندسی "
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    onChange={this.onValueChange("nezam")}
                  />
                  <TextField
                    fullWidth
                    id="outlined-number"
                    label="مقدار کل "
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    onChange={this.onValueChange("total")}
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={this.handleCloseModal("openCalculator")}
                    color="primary"
                  >
                    لغو
                  </Button>
                  <Button onClick={this.handleClose} color="primary">
                    محاسبه
                  </Button>
                </DialogActions>
              </Dialog>
              {/* <span>${this.state.estimated}</span> + 5% */}
              <span> {this.state.price}</span> تومان
            </div>
          </div>
          <div className="fotter_confirm">
            <div className="fotter-conf">
              <Button
                variant="contained"
                color="primary"
                className="footer-btns"
                onClick={this.reset}
              >
                شروع دوباره
              </Button>
            </div>
            <div className="fotter-conf">
              <Button
                variant="contained"
                color="primary"
                className="footer-btns"
                onClick={this.estimate}
              >
                تایید
              </Button>
            </div>
          </div>
          <div>
            <Dialog
              open={this.state.openPlan}
              onClose={this.handleCloseModal("openPlan")}
              aria-labelledby="form-dialog-title"
              className="dialog_confirm"
            >
              <DialogTitle id="form-dialog-title">نقشه</DialogTitle>
              <DialogContent>
                <DialogContentText>نقشه ساختمان</DialogContentText>
                <img
                  className="planimage"
                  src={BuidlingImage}
                  alt="BuidlingImage"
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.handleCloseModal("openPlan")}
                  color="primary"
                >
                  بستن
                </Button>
                <Button variant="contained" color="primary" href={Mapfile}>
                  دانلود نقشه
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={this.state.openBuilding}
              onClose={this.handleCloseModal("openBuilding")}
              aria-labelledby="form-dialog-title"
              className="dialog_confirm"
            >
              <DialogTitle id="form-dialog-title">سازه</DialogTitle>
              <DialogContent>
                <DialogContentText>تصاویر سازه</DialogContentText>
                <div className="image_hodler">
                  <img className="modalimage" src={Saze1} alt="Saze1" />
                  <img className="modalimage" src={Saze2} alt="Saze1" />
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.handleCloseModal("openBuilding")}
                  color="primary"
                >
                  بستن
                </Button>
                <Button variant="contained" color="primary" href={SazeDownload}>
                  دانلود تصاویر
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={this.state.openHardening}
              onClose={this.handleCloseModal("openHardening")}
              aria-labelledby="form-dialog-title"
              className="dialog_confirm"
            >
              <DialogTitle id="form-dialog-title">سفت کاری</DialogTitle>
              <DialogContent>
                <DialogContentText>تصاویر سفت کاری</DialogContentText>
                <div className="image_hodler">
                  <img className="modalimage" src={Seft1} alt="Saze1" />
                  <img className="modalimage" src={Seft2} alt="Saze1" />
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.handleCloseModal("openHardening")}
                  color="primary"
                >
                  بستن
                </Button>
                <Button variant="contained" color="primary" href={SeftDownload}>
                  دانلود تصاویر
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={this.state.openJoinery}
              onClose={this.handleCloseModal("openJoinery")}
              aria-labelledby="form-dialog-title"
              className="dialog_confirm"
            >
              <DialogTitle id="form-dialog-title">نازک کاری</DialogTitle>
              <DialogContent>
                <DialogContentText>تصاویر نازک کاری</DialogContentText>
                <div className="image_hodler">
                  <img className="modalimage" src={Nazok1} alt="Saze1" />
                  <img className="modalimage" src={Nazok2} alt="Saze1" />
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.handleCloseModal("openJoinery")}
                  color="primary"
                >
                  بستن
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  href={NazokDownload}
                >
                  دانلود تصاویر
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
        {/* <div className="footer">{this.renderBackBtn()}</div> */}
      </div>
    );
  }
}

export default ConfirmContractor;
