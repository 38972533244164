import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Grid from "@material-ui/core/Grid";
import AccountCircle from "@material-ui/icons/AccountCircle";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import FormControl from "@material-ui/core/FormControl";

import "../elements.css";

export class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      showPassword: false,
      username: "",
      setOpen: false,
    };
  }

  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  handleChange = (prop) => (event) => {
    this.setState({
      [prop]: event.target.value,
    });
  };

  handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({
      setOpen: false,
    });
  };

  handleLogin = (e) => {
    if (this.state.username === "admin" && this.state.password === "admin") {
      e.preventDefault();
      this.props.nextStep();
    } else {
      this.setState({
        setOpen: true,
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="main">
          <div className="main-box">
            <div className="container space-2 space-md-3">
              <div className="steps">
                <div className="main-screen">
                  <br />
                  <Typography
                    variant="h4"
                    className="title font-weight-bold Title-elements"
                  >
                    ورود به سیستم
                  </Typography>
                  <br />
                  <div className="allItems">
                    <Snackbar
                      open={this.state.setOpen}
                      autoHideDuration={6000}
                      onClose={this.handleClose}
                    >
                      <this.Alert onClose={this.handleClose} severity="error">
                        نام کاربری یا رمز عبور اشتباه است
                      </this.Alert>
                    </Snackbar>
                    <Grid container spacing={2} alignItems="flex-end">
                      <Grid item>
                        <FormControl>
                          <InputLabel
                            htmlFor="standard-adornment-username"
                            className="custom-labelinput"
                          >
                            نام کاربری
                          </InputLabel>
                          <Input
                            id="standard-adornment-username"
                            value={this.state.username}
                            onChange={this.handleChange("username")}
                            endAdornment={
                              <InputAdornment position="start">
                                <AccountCircle />
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item>
                        <FormControl>
                          <InputLabel
                            htmlFor="standard-adornment-password"
                            className="custom-labelinput"
                          >
                            رمز عبور
                          </InputLabel>
                          <Input
                            id="standard-adornment-password"
                            type={this.state.showPassword ? "text" : "password"}
                            value={this.state.password}
                            onChange={this.handleChange("password")}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={this.handleClickShowPassword}
                                  onMouseDown={this.handleMouseDownPassword}
                                >
                                  {this.state.showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <div className="fotter-back">
                      <Button
                        variant="contained"
                        color="primary"
                        className="footer-btn-back"
                        onClick={this.back}
                      >
                        قبل
                      </Button>
                    </div>
                    <div className="fotter">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleLogin}
                        className="footer-btn-next"
                      >
                        ورود
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Login;
