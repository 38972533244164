import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Header from "./header/header";
import SelectItems from "./Select";
import { DateObject, Calendar } from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";

import "./elements/elements.css";
import { Typography } from "@material-ui/core";

import "./Confirm.css";

import img from "../assets/005-architect.svg";

// import axios from "axios";
// import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
// import BuidlingImage from "../assets/modals/map.jpg";
// import Saze1 from "../assets/modals/saze1.jpg";
// import Saze2 from "../assets/modals/saze2.jpg";
// import Seft1 from "../assets/modals/seft1.jpg";
// import Seft2 from "../assets/modals/seft2.jpg";
// import Nazok1 from "../assets/modals/nazok1.jpg";
// import Nazok2 from "../assets/modals/nazok2.jpg";

// import Allmaps from "../assets/modals/allmaps.rar";
// import Analyse from "../assets/modals/analyse.xlsx";
// import Mapfile from "../assets/modals/map.pdf";
import Ten from "../assets/modals/10-10.rar";
import Twoelve from "../assets/modals/10-12.rar";
import Site from "../assets/modals/site.rar";
// import SazeDownload from "../assets/modals/saze.zip";
// import SeftDownload from "../assets/modals/seftkari.zip";
// import NazokDownload from "../assets/modals/nazokkari.zip";

export class Confirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      estimated: null,
      openCalculator: false,
      openPlan: false,
      openBuilding: false,
      openHardening: false,
      openJoinery: false,
      openInfo: false,
      openTashilat: false,
      openFinance: false,
      metraj: 0,

      coller: 50000000,
      package: 140000000,
      radiator: 140000000,
      bokhari: 30000000,
      abgarmkon: 52000000,
      dastshoyi: 40000000,
      kabinet: 300000000,
      komod: 50000000,

      metreBaravord: 4250000000,
      zaribTadil: 1.25,
      abnie: 1,
      balasariPeyman: 2082500000,
      kargah: 212500000,
      maliat: 10000000,
      parvane: 1500000,
      ensheab: 120000000,
      zamin: 720000000,
      nezam: 200000,
      tarhin: 60000000,
      mohavate: 400000000,
      maliatarzeshafzode: 1,

      total: 0,
      price: 0,
      tashilatType: "",
      tashilatPrice: 0,
      setOpen: false,
      dense: false,
      debt: 1,
      currentDate: new DateObject({ calendar: persian, locale: persian_fa }),
      debt_day: undefined,
      debt_months: [],
      months: [0, 3, 6, 9],
    };
  }

  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
    // console.log(this.props);
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  renderBackBtn() {
    switch (this.props.data.category) {
      case "buyer":
        return (
          <div className="fotter-back">
            <Button
              variant="contained"
              color="primary"
              className="footer-btn-back"
              onClick={this.back}
            >
              قبل
            </Button>
          </div>
        );
      case "contractor":
        return (
          <div className="fotter-back">
            <Button
              variant="contained"
              color="primary"
              className="footer-btn-back"
              onClick={this.back}
            >
              قبل
            </Button>
          </div>
        );
      default:
        break;
    }
  }
  updateTotal = () => {
    let price_other = 0;
    this.props.data.uniqcooling.map((option) => {
      switch (option) {
        case "کولر آبی":
          price_other = price_other + this.state.coller;
          break;
        case "پکیج":
          price_other = price_other + this.state.package;

          break;
        case "رادیاتور":
          price_other = price_other + this.state.radiator;
          break;
        case "بخاری":
          price_other = price_other + this.state.bokhari;
          break;
        case "آبگرمکن":
          price_other = price_other + this.state.abgarmkon;
          break;
        case "توالت فرنگی":
          price_other = price_other + this.state.dastshoyi;
          break;
        default:
          break;
      }
    });
    this.props.data.uniqkabinets.map((option) => {
      switch (option) {
        case "کابینت":
          price_other = price_other + this.state.kabinet;
          break;
        case "کمد دیواری":
          price_other = price_other + this.state.komod;
          break;
        default:
          break;
      }
    });
    return price_other;
  };

  componentDidMount = () => {
    let price_other = 0;
    switch (this.props.data.bedroom) {
      case "1":
        var size = 86;
        let yekhab_seramik = 8828417517;
        let yekhab_siman = yekhab_seramik - yekhab_seramik * 0.08;
        let yekhab_moket = yekhab_siman + 15000000;
        let yekhab_mozek = yekhab_seramik - yekhab_seramik * 0.07;
        let yekhab_parket = yekhab_mozek + 64500000;
        switch (this.props.data.floor) {
          case "سرامیک":
            price_other = this.updateTotal();
            this.setState({
              total:
                yekhab_seramik +
                yekhab_seramik * 0.04 +
                this.state.maliat +
                this.state.parvane * size +
                this.state.ensheab +
                this.state.zamin +
                this.state.nezam * size +
                this.state.tarhin +
                this.state.mohavate +
                yekhab_seramik * 0.09 +
                price_other,
              abnie: yekhab_seramik,
              kargah: yekhab_seramik * 0.04,
              parvane: this.state.parvane * size,
              nezam: this.state.nezam * size,
              maliatarzeshafzode: yekhab_seramik * 0.09,
            });
            break;
          case "موکت":
            price_other = this.updateTotal();
            this.setState({
              total:
                yekhab_moket +
                yekhab_moket * 0.04 +
                this.state.maliat +
                this.state.parvane * size +
                this.state.ensheab +
                this.state.zamin +
                this.state.nezam * size +
                this.state.tarhin +
                this.state.mohavate +
                yekhab_moket * 0.09 +
                price_other,
              abnie: yekhab_moket,
              kargah: yekhab_moket * 0.04,
              parvane: this.state.parvane * size,
              nezam: this.state.nezam * size,
              maliatarzeshafzode: yekhab_moket * 0.09,
            });
            break;
          case "پارکت":
            price_other = this.updateTotal();
            this.setState({
              total:
                yekhab_parket +
                yekhab_parket * 0.04 +
                this.state.maliat +
                this.state.parvane * size +
                this.state.ensheab +
                this.state.zamin +
                this.state.nezam * size +
                this.state.tarhin +
                this.state.mohavate +
                yekhab_parket * 0.09 +
                price_other,
              abnie: yekhab_parket,
              kargah: yekhab_parket * 0.04,
              parvane: this.state.parvane * size,
              nezam: this.state.nezam * size,
              maliatarzeshafzode: yekhab_parket * 0.09,
            });
            break;
          case "سیمان":
            price_other = this.updateTotal();
            this.setState({
              total:
                yekhab_siman +
                yekhab_siman * 0.04 +
                this.state.maliat +
                this.state.parvane * size +
                this.state.ensheab +
                this.state.zamin +
                this.state.nezam * size +
                this.state.tarhin +
                this.state.mohavate +
                yekhab_siman * 0.09 +
                price_other,
              abnie: yekhab_siman,
              kargah: yekhab_siman * 0.04,
              parvane: this.state.parvane * size,
              nezam: this.state.nezam * size,
              maliatarzeshafzode: yekhab_siman * 0.09,
            });
            break;
          case "موزائیک":
            price_other = this.updateTotal();
            this.setState({
              total:
                yekhab_mozek +
                yekhab_mozek * 0.04 +
                this.state.maliat +
                this.state.parvane * size +
                this.state.ensheab +
                this.state.zamin +
                this.state.nezam * size +
                this.state.tarhin +
                this.state.mohavate +
                yekhab_mozek * 0.09 +
                price_other,
              abnie: yekhab_mozek,
              kargah: yekhab_mozek * 0.04,
              parvane: this.state.parvane * size,
              nezam: this.state.nezam * size,
              maliatarzeshafzode: yekhab_mozek * 0.09,
            });
            break;
          default:
            this.setState({
              total:
                10685472998 +
                10685472998 * 0.04 +
                this.state.maliat +
                this.state.parvane * 120 +
                this.state.ensheab +
                this.state.zamin +
                this.state.nezam * 120 +
                this.state.tarhin +
                this.state.mohavate +
                10685472998 * 0.09,
              abnie: 10685472998,
              kargah: 10685472998 * 0.04,
              parvane: this.state.parvane * 120,
              nezam: this.state.nezam * 120,
              maliatarzeshafzode: 10685472998 * 0.09,
            });
            break;
        }
        break;
      case "2":
        size = 102;
        let dokhab_seramik = 10122454845;
        let dokhab_siman = dokhab_seramik - dokhab_seramik * 0.08;
        let dokhab_moket = dokhab_siman + 20000000;
        let dokhab_mozek = dokhab_seramik - dokhab_seramik * 0.07;
        let dokhab_parket = dokhab_mozek + 88500000;
        switch (this.props.data.floor) {
          case "سرامیک":
            price_other = this.updateTotal();
            this.setState({
              total:
                dokhab_seramik +
                dokhab_seramik * 0.04 +
                this.state.maliat +
                this.state.parvane * size +
                this.state.ensheab +
                this.state.zamin +
                this.state.nezam * size +
                this.state.tarhin +
                this.state.mohavate +
                dokhab_seramik * 0.09 +
                price_other,
              abnie: dokhab_seramik,
              kargah: dokhab_seramik * 0.04,
              parvane: this.state.parvane * size,
              nezam: this.state.nezam * size,
              maliatarzeshafzode: dokhab_seramik * 0.09,
            });
            break;
          case "سیمان":
            price_other = this.updateTotal();
            this.setState({
              total:
                dokhab_siman +
                dokhab_siman * 0.04 +
                this.state.maliat +
                this.state.parvane * size +
                this.state.ensheab +
                this.state.zamin +
                this.state.nezam * size +
                this.state.tarhin +
                this.state.mohavate +
                dokhab_siman * 0.09 +
                price_other,
              abnie: dokhab_siman,
              kargah: dokhab_siman * 0.04,
              parvane: this.state.parvane * size,
              nezam: this.state.nezam * size,
              maliatarzeshafzode: dokhab_siman * 0.09,
            });
            break;
          case "موزائیک":
            price_other = this.updateTotal();
            this.setState({
              total:
                dokhab_mozek +
                dokhab_mozek * 0.04 +
                this.state.maliat +
                this.state.parvane * size +
                this.state.ensheab +
                this.state.zamin +
                this.state.nezam * size +
                this.state.tarhin +
                this.state.mohavate +
                dokhab_mozek * 0.09 +
                price_other,
              abnie: dokhab_mozek,
              kargah: dokhab_mozek * 0.04,
              parvane: this.state.parvane * size,
              nezam: this.state.nezam * size,
              maliatarzeshafzode: dokhab_mozek * 0.09,
            });
            break;
          case "موکت":
            price_other = this.updateTotal();
            this.setState({
              total:
                dokhab_moket +
                dokhab_moket * 0.04 +
                this.state.maliat +
                this.state.parvane * size +
                this.state.ensheab +
                this.state.zamin +
                this.state.nezam * size +
                this.state.tarhin +
                this.state.mohavate +
                dokhab_moket * 0.09 +
                price_other,
              abnie: dokhab_moket,
              kargah: dokhab_moket * 0.04,
              parvane: this.state.parvane * size,
              nezam: this.state.nezam * size,
              maliatarzeshafzode: dokhab_moket * 0.09,
            });
            break;
          case "پارکت":
            price_other = this.updateTotal();
            this.setState({
              total:
                dokhab_parket +
                dokhab_parket * 0.04 +
                this.state.maliat +
                this.state.parvane * size +
                this.state.ensheab +
                this.state.zamin +
                this.state.nezam * size +
                this.state.tarhin +
                this.state.mohavate +
                dokhab_parket * 0.09 +
                price_other,
              abnie: dokhab_parket,
              kargah: dokhab_parket * 0.04,
              parvane: this.state.parvane * size,
              nezam: this.state.nezam * size,
              maliatarzeshafzode: dokhab_parket * 0.09,
            });
            break;
          default:
            this.setState({
              total:
                10685472998 +
                10685472998 * 0.04 +
                this.state.maliat +
                this.state.parvane * 120 +
                this.state.ensheab +
                this.state.zamin +
                this.state.nezam * 120 +
                this.state.tarhin +
                this.state.mohavate +
                10685472998 * 0.09,
              abnie: 10685472998,
              kargah: 10685472998 * 0.04,
              parvane: this.state.parvane * 120,
              nezam: this.state.nezam * 120,
              maliatarzeshafzode: 10685472998 * 0.09,
            });
            break;
        }
        break;
      case "3":
        size = 120;
        let sekhab_seramik = 11604250286;
        let sekhab_siman = sekhab_seramik - sekhab_seramik * 0.08;
        let sekhab_moket = sekhab_siman + 26250000;
        let sekhab_mozek = sekhab_seramik - sekhab_seramik * 0.07;
        let sekhab_parket = sekhab_mozek + 112500000;
        switch (this.props.data.floor) {
          case "سرامیک":
            price_other = this.updateTotal();
            this.setState({
              total:
                sekhab_seramik +
                sekhab_seramik * 0.04 +
                this.state.maliat +
                this.state.parvane * size +
                this.state.ensheab +
                this.state.zamin +
                this.state.nezam * size +
                this.state.tarhin +
                this.state.mohavate +
                sekhab_seramik * 0.09 +
                price_other,
              abnie: sekhab_seramik,
              kargah: sekhab_seramik * 0.04,
              parvane: this.state.parvane * size,
              nezam: this.state.nezam * size,
              maliatarzeshafzode: sekhab_seramik * 0.09,
            });
            break;
          case "سیمان":
            price_other = this.updateTotal();
            this.setState({
              total:
                sekhab_siman +
                sekhab_siman * 0.04 +
                this.state.maliat +
                this.state.parvane * size +
                this.state.ensheab +
                this.state.zamin +
                this.state.nezam * size +
                this.state.tarhin +
                this.state.mohavate +
                sekhab_siman * 0.09 +
                price_other,
              abnie: sekhab_siman,
              kargah: sekhab_siman * 0.04,
              parvane: this.state.parvane * size,
              nezam: this.state.nezam * size,
              maliatarzeshafzode: sekhab_siman * 0.09,
            });
            break;
          case "موزائیک":
            price_other = this.updateTotal();
            this.setState({
              total:
                sekhab_mozek +
                sekhab_mozek * 0.04 +
                this.state.maliat +
                this.state.parvane * 120 +
                this.state.ensheab +
                this.state.zamin +
                this.state.nezam * 120 +
                this.state.tarhin +
                this.state.mohavate +
                sekhab_mozek * 0.09 +
                price_other,
              abnie: sekhab_mozek,
              kargah: sekhab_mozek * 0.04,
              parvane: this.state.parvane * 120,
              nezam: this.state.nezam * 120,
              maliatarzeshafzode: sekhab_mozek * 0.09,
            });
            break;
          case "موکت":
            price_other = this.updateTotal();
            this.setState({
              total:
                sekhab_moket +
                sekhab_moket * 0.04 +
                this.state.maliat +
                this.state.parvane * size +
                this.state.ensheab +
                this.state.zamin +
                this.state.nezam * size +
                this.state.tarhin +
                this.state.mohavate +
                sekhab_moket * 0.09 +
                price_other,
              abnie: sekhab_moket,
              kargah: sekhab_moket * 0.04,
              parvane: this.state.parvane * size,
              nezam: this.state.nezam * size,
              maliatarzeshafzode: sekhab_moket * 0.09,
            });
            break;
          case "پارکت":
            price_other = this.updateTotal();
            this.setState({
              total:
                sekhab_parket +
                sekhab_parket * 0.04 +
                this.state.maliat +
                this.state.parvane * size +
                this.state.ensheab +
                this.state.zamin +
                this.state.nezam * size +
                this.state.tarhin +
                this.state.mohavate +
                sekhab_parket * 0.09 +
                price_other,
              abnie: sekhab_parket,
              kargah: sekhab_parket * 0.04,
              parvane: this.state.parvane * size,
              nezam: this.state.nezam * size,
              maliatarzeshafzode: sekhab_parket * 0.09,
            });
            break;
          default:
            this.setState({
              total:
                10685472998 +
                10685472998 * 0.04 +
                this.state.maliat +
                this.state.parvane * 120 +
                this.state.ensheab +
                this.state.zamin +
                this.state.nezam * 120 +
                this.state.tarhin +
                this.state.mohavate +
                10685472998 * 0.09,
              abnie: 10685472998,
              kargah: 10685472998 * 0.04,
              parvane: this.state.parvane * 120,
              nezam: this.state.nezam * 120,
              maliatarzeshafzode: 10685472998 * 0.09,
            });
            break;
        }
        break;
      default:
        break;
    }
    if (this.state.currentDate.monthIndex % 3 === 0) {
      this.setState({
        debt_months: [...this.state.months],
      });
    }
    if ((this.state.currentDate.monthIndex - 1) % 3 === 0) {
      this.state.months.map((month) => {
        this.state.debt_months.push(month + 1);
      });
    }
    if ((this.state.currentDate.monthIndex + 1) % 3 === 0) {
      this.state.months.map((month) => {
        this.state.debt_months.push(month - 1);
      });
    }
    this.setState({
      debt_day: this.state.currentDate.day + 10,
    });
    this.props.datas.push(this.props.data);
    for (let i = 0; i < this.props.datas.length; i++) {
      this.setState({
        items: this.props.data.items.push(
          <option value={i}> {this.props.datas[i].category} </option>
        ),
      });
    }
  };

  reset = () => {
    this.props.reset();
  };

  add = () => {
    this.props.add();
  };

  handleOpenModal = (option) => () => {
    this.setState({
      [option]: true,
    });
  };
  handleCloseModal = (option) => () => {
    this.setState({
      [option]: false,
    });
  };

  handleClose = () => {
    let totalprice =
      this.state.bime +
      this.state.avarez +
      this.state.amadesazi +
      this.state.nezam +
      this.state.total;
    this.setState({
      price: totalprice,
      openCalculator: false,
    });
  };

  renderContractorBtn() {
    if (this.props.data.category === "contractor") {
      return (
        <div>
          <Button
            variant="contained"
            color="primary"
            className="plans_btn"
            onClick={this.handleOpenModal("openInfo")}
          >
            دانلود اطلاعات
          </Button>
          <Dialog
            open={this.state.openInfo}
            onClose={this.handleCloseModal("openInfo")}
            aria-labelledby="form-dialog-title"
            className="dialog_confirm"
          >
            <DialogTitle id="form-dialog-title">دانلود اطلاعات</DialogTitle>
            <DialogContent>
              <DialogContentText>دانلود اطلاعات و نقشه‌ها</DialogContentText>
              <Button
                variant="outlined"
                color="primary"
                className="info_btns"
                href={Ten}
              >
                دانلود ۱۰*۱۰
              </Button>
              <Button
                variant="outlined"
                color="primary"
                className="info_btns"
                href={Twoelve}
              >
                دانلود ۱۰*۱۲
              </Button>
              <Button
                variant="outlined"
                color="primary"
                className="info_btns"
                href={Site}
              >
                دانلود سایت
              </Button>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.handleCloseModal("openInfo")}
                color="primary"
              >
                بستن
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
    }
  }
  renderTashihatBtn() {
    if (this.props.data.category === "buyer") {
      return (
        <div>
          <Button
            variant="contained"
            color="primary"
            className="footer-btns"
            onClick={this.handleOpenModal("openTashilat")}
          >
            تسهیلات
          </Button>
          <Dialog
            open={this.state.openTashilat}
            onClose={this.handleCloseModal("openTashilat")}
            aria-labelledby="form-dialog-title"
            className="dialog_confirm"
          >
            <DialogTitle id="form-dialog-title">محاسبه گر تسهیلات</DialogTitle>
            <DialogContent>
              <DialogContentText>
                لطفا مقدار تسهیلات را انتخاب نمایید
              </DialogContentText>
              <FormControl>
                <InputLabel id="demo-simple-select-helper-label">
                  نوع تسهیلات
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={this.state.tashilatType}
                  onChange={this.handleTashilat}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={300}>۳۰۰ </MenuItem>
                  <MenuItem value={500}>۵۰۰ </MenuItem>
                </Select>
                <FormHelperText>
                  نوع تسهیلات بر اساس میلیون تومان
                </FormHelperText>
                <div className="tashilat">
                  <p>بازپرداخت اقساط به صورت ۴ عدد چک به مبلغ</p>
                  <span className="tashilat_price">
                    {this.state.tashilatPrice}
                  </span>
                  <p> میلیون تومان هر سه ماه یکبار است</p>
                </div>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.handleCloseModal("openTashilat")}
                color="primary"
              >
                بستن
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
    }
  }

  onValueChange = (option) => (event) => {
    this.setState({
      [option]: Number(event.target.value),
    });
  };

  handleTashilat = (event) => {
    this.setState({
      tashilatType: event.target.value,
    });
    if (event.target.value === 300) {
      this.setState({
        tashilatPrice: 100,
      });
    } else {
      this.setState({
        tashilatPrice: 150,
      });
    }
  };

  Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  confirm = () => {
    this.setState({
      setOpen: true,
    });
  };

  render() {
    // const { onValueChange, size } = this.props;

    return (
      <div>
        <Header Title="سیستم انتخاب خانه"></Header>
        <div className="invoice">
          <div className="headLine items">
            <img className="icon" src={img} alt="mast dg" />
            <Typography variant="h6" className="head-title">
              مرحله پایانی
            </Typography>
          </div>
          <Snackbar
            open={this.state.setOpen}
            autoHideDuration={3000}
            onClose={this.handleClose}
          >
            <this.Alert onClose={this.handleClose} severity="success">
              واحد A3 به شما تخصیص داده شد
            </this.Alert>
          </Snackbar>
          <SelectItems
            data={this.props.data}
            datas={this.props.datas}
          ></SelectItems>
          <div className="Estimate items">
            {/* <Typography variant="h5">پیش بینی هزینه‌ها</Typography> */}
            <div class="price-container">
              {/* {this.renderTashihatBtn()} */}
              <Button
                variant="contained"
                color="primary"
                className="footer-btns"
                onClick={this.handleOpenModal("openCalculator")}
              >
                نمایش ریز هزینه
              </Button>
              <Dialog
                open={this.state.openCalculator}
                onClose={this.handleCloseModal("openCalculator")}
                aria-labelledby="form-dialog-title"
                className="dialog_confirm dialog_calculator"
              >
                <DialogTitle id="form-dialog-title">ریز هزینه ها</DialogTitle>
                <DialogContent>
                  <DialogContentText>ریز هزینه تمام شده</DialogContentText>
                  <div>
                    <List dense={this.state.dense}>
                      <ListItem className="listitem">
                        <ListItemIcon>
                          <AccountBalanceWalletIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary="هزینه ساخت ابنیه با تعدیل "
                          className="listtext"
                        />
                        <span>
                          {this.state.abnie.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}
                        </span>
                      </ListItem>
                      <ListItem className="listitem">
                        <ListItemIcon>
                          <AccountBalanceWalletIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary="هزینه تجهیز کارگاه "
                          className="listtext"
                        />
                        <span>
                          {this.state.kargah.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}
                        </span>
                      </ListItem>
                      <ListItem className="listitem">
                        <ListItemIcon>
                          <AccountBalanceWalletIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary="هزینه مالیات "
                          className="listtext"
                        />
                        <span>
                          {this.state.maliat.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}
                        </span>
                      </ListItem>
                      <ListItem className="listitem">
                        <ListItemIcon>
                          <AccountBalanceWalletIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary="هزینه عوارض صدور پروانه "
                          className="listtext"
                        />
                        <span>
                          {this.state.parvane.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}
                        </span>
                      </ListItem>
                      <ListItem className="listitem">
                        <ListItemIcon>
                          <AccountBalanceWalletIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary="هزینه انشعابات "
                          className="listtext"
                        />
                        <span>
                          {this.state.ensheab.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}
                        </span>
                      </ListItem>
                      <ListItem className="listitem">
                        <ListItemIcon>
                          <AccountBalanceWalletIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary="هزینه آماده سازی زمین "
                          className="listtext"
                        />
                        <span>
                          {this.state.zamin.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}
                        </span>
                      </ListItem>
                      <ListItem className="listitem">
                        <ListItemIcon>
                          <AccountBalanceWalletIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary="هزینه نظام مهندسی "
                          className="listtext"
                        />
                        <span>
                          {this.state.nezam.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}
                        </span>
                      </ListItem>
                      <ListItem className="listitem">
                        <ListItemIcon>
                          <AccountBalanceWalletIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary="هزینه ترهین اسناد و ارزیابی "
                          className="listtext"
                        />
                        <span>
                          {this.state.tarhin.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}
                        </span>
                      </ListItem>
                      <ListItem className="listitem">
                        <ListItemIcon>
                          <AccountBalanceWalletIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary="هزینه محوطه سازی "
                          className="listtext"
                          // secondary="مساحت بنا از مساحت زمین کسر شده و در200.000 تومان ضرب می شود"
                        />
                        <span>
                          {this.state.mohavate.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}
                        </span>
                      </ListItem>
                      <ListItem className="listitem">
                        <ListItemIcon>
                          <AccountBalanceWalletIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary="مالیات بر ارزش افزوده "
                          className="listtext"
                          // secondary="مساحت بنا از مساحت زمین کسر شده و در200.000 تومان ضرب می شود"
                        />
                        <span>
                          {this.state.maliatarzeshafzode.toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            }
                          )}
                        </span>
                      </ListItem>
                      <ListItem className="listitem">
                        <ListItemIcon>
                          <AccountBalanceWalletIcon />
                        </ListItemIcon>
                        <ListItemText primary="مقدار کل" className="listtext" />
                        <span>
                          {this.state.total.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}
                        </span>
                      </ListItem>
                    </List>
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={this.handleCloseModal("openCalculator")}
                    color="primary"
                  >
                    بستن
                  </Button>
                </DialogActions>
              </Dialog>
              {/* <span>${this.state.estimated}</span> + 5% */}
              <span>
                {this.state.total.toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}{" "}
                <span id="currency">ریال</span>
              </span>
              <Button
                variant="contained"
                color="primary"
                className="footer-btns"
                onClick={this.handleOpenModal("openFinance")}
              >
                جدول پرداخت اقساط
              </Button>
              <Dialog
                open={this.state.openFinance}
                onClose={this.handleCloseModal("openFinance")}
                aria-labelledby="form-dialog-title"
                className="dialog_confirm debet_modal"
              >
                <DialogTitle id="form-dialog-title">
                  مشاهده جدول پرداخت ها
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>مشاهده زمان بازپرداخت</DialogContentText>
                  <Typography variant="h5" color="inherit" id="calculatorline">
                    <span>
                      {this.state.total.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                    </span>{" "}
                    - <span>{(3500000000).toLocaleString()}</span> ={" "}
                    <span>
                      {(this.state.total - 3500000000).toLocaleString(
                        undefined,
                        { minimumFractionDigits: 0, maximumFractionDigits: 0 }
                      )}
                    </span>
                  </Typography>
                  <br />
                  <div className="infobox">
                    <Typography variant="h5" color="inherit">
                      مبلغ هر قسط :‌
                    </Typography>
                    <Typography variant="h5" color="inherit" id="total_debt">
                      {((this.state.total - 3500000000) / 9).toLocaleString(
                        undefined,
                        { minimumFractionDigits: 0, maximumFractionDigits: 0 }
                      )}
                      ریال
                    </Typography>
                  </div>
                  <Typography variant="h5" color="inherit" className="debt_txt">
                    فاصله زمانی هر قسط ۹۰ روز می‌باشد که در تاریخ های مشخص است.{" "}
                  </Typography>
                  <Calendar
                    mapDays={({ date, currentMonth, today }) => {
                      let props = {};
                      if (
                        currentMonth.index === this.state.currentDate.monthIndex
                      ) {
                        if (date.day === this.state.debt_day)
                          props.style = {
                            borderRadius: "3px",
                            color: "white",
                            backgroundColor: "red",
                          };
                      }
                      if (this.state.debt_months.includes(date.monthIndex)) {
                        if (date.day === this.state.debt_day)
                          props.style = {
                            borderRadius: "3px",
                            color: "white",
                            backgroundColor: "red",
                          };
                      }

                      return props;
                    }}
                    readOnly={true}
                    numberOfMonths={1}
                    disableMonthPicker
                    disableYearPicker
                    calendar={persian}
                    locale={persian_fa}
                    calendarPosition="bottom-right"
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={this.handleCloseModal("openFinance")}
                    color="primary"
                  >
                    بستن
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>

          <div className="fotter_confirm">
            <div className="fotter-conf">
              <Button
                variant="contained"
                color="primary"
                className="footer-btns"
                onClick={this.reset}
              >
                شروع دوباره
              </Button>
            </div>
            <div className="fotter-conf">
              <Button
                variant="contained"
                color="primary"
                className="footer-btns"
                onClick={this.continue}
              >
                اطلاعات تکمیلی
              </Button>
            </div>
            <div className="fotter-conf">
              <Button
                variant="contained"
                color="primary"
                className="footer-btns"
                onClick={this.confirm}
              >
                تایید
              </Button>
            </div>
          </div>
        </div>
        {/* <div className="footer">{this.renderBackBtn()}</div> */}
      </div>
    );
  }
}

export default Confirm;
