import React, { Component } from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
// import Button from '@material-ui/core/Button';
// import Typography from '@material-ui/core/Typography';
// import Paper from '@material-ui/core/Paper';

import "./Sidebar.css";

export class Sidebar extends Component {
  handleReset = () => {
    this.setState({
      step: 1,
    });
  };

  render() {
    // console.log(this.props.scrollTop)

    return (
      <div className="template">
        <Stepper
          activeStep={this.props.stepper}
          alternativeLabel={this.props.scrollTop}
          className="sidenav"
        >
          {this.props.stepperList.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
    );
  }
}

export default Sidebar;
