import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import CustomCardRadio from "../../customCard/CustomCardRadio";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import exteriorSiding from "../../../assets/svg/001-house.svg";

import "../elements.css";

import room1 from "../../../assets/modals/room1.jpg";
import room2 from "../../../assets/modals/room2.jpg";
import room3 from "../../../assets/modals/room3.jpg";

export class Bedroom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openRoom: false,
      openRoom2: false,
      openRoom3: false,
    };
  }

  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };
  handleOpenModal = (option) => () => {
    this.setState({
      [option]: true,
    });
  };
  handleCloseModal = (option) => () => {
    this.setState({
      [option]: false,
    });
  };
  render() {
    const { values, onValueChange } = this.props;

    return (
      <React.Fragment>
        <div className="main">
          <div className="main-box">
            <div className="container space-2 space-md-3">
              <div className="steps">
                <div className="main-screen">
                  <br />
                  <Typography variant="h4" className="title">
                    تعدا اتاق خواب خود را انتخاب کنید
                  </Typography>
                  <br />
                  <div className="allItems">
                    <label className="bedrooms">
                      <input
                        type="radio"
                        value="1"
                        onChange={onValueChange("bedroom")}
                        checked={values.option === "1"}
                        onClick={this.continue}
                      ></input>
                      <CustomCardRadio
                        img={exteriorSiding}
                        Title={"یک"}
                      ></CustomCardRadio>
                      <Button
                        variant="outlined"
                        color="primary"
                        className="plans_btn"
                        onClick={this.handleOpenModal("openRoom")}
                      >
                        نمایش نقشه
                      </Button>
                      <Dialog
                        open={this.state.openRoom}
                        onClose={this.handleCloseModal("openRoom")}
                        aria-labelledby="form-dialog-title"
                        className="dialog_confirm"
                      >
                        <DialogTitle id="form-dialog-title">
                          نقشه اتاق
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText>
                            نقشه اتاق یک خوابه
                          </DialogContentText>
                          <img className="modalimage" src={room1} alt="Saze1" />
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={this.handleCloseModal("openRoom")}
                            color="primary"
                          >
                            بستن
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </label>
                    <label className="bedrooms">
                      <input
                        type="radio"
                        value="2"
                        onChange={onValueChange("bedroom")}
                        checked={values.option === "2"}
                        onClick={this.continue}
                      ></input>
                      <CustomCardRadio
                        img={exteriorSiding}
                        Title={"دو"}
                      ></CustomCardRadio>
                      <Button
                        variant="outlined"
                        color="primary"
                        className="plans_btn"
                        onClick={this.handleOpenModal("openRoom2")}
                      >
                        نمایش نقشه
                      </Button>
                      <Dialog
                        open={this.state.openRoom2}
                        onClose={this.handleCloseModal("openRoom2")}
                        aria-labelledby="form-dialog-title"
                        className="dialog_confirm"
                      >
                        <DialogTitle id="form-dialog-title">
                          نقشه اتاق
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText>
                            نقشه اتاق دو خوابه
                          </DialogContentText>
                          <img className="modalimage" src={room2} alt="Saze1" />
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={this.handleCloseModal("openRoom2")}
                            color="primary"
                          >
                            بستن
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </label>
                    <label className="bedrooms">
                      <input
                        type="radio"
                        value="3"
                        onChange={onValueChange("bedroom")}
                        checked={values.option === "3"}
                        onClick={this.continue}
                      ></input>
                      <CustomCardRadio
                        img={exteriorSiding}
                        Title={"سه"}
                      ></CustomCardRadio>
                      <Button
                        variant="outlined"
                        color="primary"
                        className="plans_btn"
                        onClick={this.handleOpenModal("openRoom3")}
                      >
                        نمایش نقشه
                      </Button>
                      <Dialog
                        open={this.state.openRoom3}
                        onClose={this.handleCloseModal("openRoom3")}
                        aria-labelledby="form-dialog-title"
                        className="dialog_confirm"
                      >
                        <DialogTitle id="form-dialog-title">
                          نقشه اتاق
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText>
                            نقشه اتاق سه خوابه
                          </DialogContentText>
                          <img className="modalimage" src={room3} alt="Saze1" />
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={this.handleCloseModal("openRoom3")}
                            color="primary"
                          >
                            بستن
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </label>
                    <div className="fotter-back">
                      <Button
                        variant="contained"
                        color="primary"
                        className="footer-btn-back"
                        onClick={this.back}
                      >
                        قبل
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Bedroom;
