import React, { Component } from "react";

import Login from "./Login";
import Reports from "./Reports";
import Soratvaziat from "./Soratvaziat";
import Nemodar from "./Nemodar";
import Ahjam from "./Ahjam";
import Pardakhti from "./Pardakhti";
import Daryafti from "./Daryafti";
import Vahedha from "./Vahedha";
import Shahrak from "./Shahrak";

import house from "../../../assets/svg/004-basement.svg";

import "../elements.css";
import Sidebar from "../../Sidebar";
import Header from "../../header/header";

export class Contractor extends Component {
  constructor() {
    super();
    this.state = {
      inStep: 1,
    };
  }

  inNextStep = () => {
    const { inStep } = this.state;
    this.setState({
      inStep: inStep + 1,
    });
  };

  prevStep = () => {
    const { inStep } = this.state;
    this.setState({
      inStep: inStep - 1,
    });
  };

  //Proced to Prev step
  nextStep = () => {
    this.props.nextStep();
  };

  //Proced to Prev step
  inPrevStep = () => {
    this.props.prevStep();
  };

  renderSidebar(inStep) {
    return (
      <Sidebar stepper={inStep} stepperList={["کاربر", "متراژ"]}></Sidebar>
    );
  }

  onPagenumberChange = (option, selectedPage) => () => {
    this.setState({
      [option]: selectedPage,
    });
    this.inNextStep();
  };

  renderSwitch(inStep, values, onValueChange, handleCheck) {
    const { page } = this.state;
    const pagenumber = { page };
    switch (inStep) {
      case 1:
        return (
          <Login
            nextStep={this.inNextStep}
            prevStep={this.inPrevStep}
            onValueChange={this.onValueChange}
            values={values}
            complete={house}
            partial={house}
          ></Login>
        );
      case 2:
        return (
          <Reports
            nextStep={this.inNextStep}
            prevStep={this.prevStep}
            onValueChange={onValueChange}
            values={values}
            onPagenumberChange={this.onPagenumberChange}
            pagenumber={pagenumber}
          ></Reports>
        );

      case 3:
        switch (page) {
          case "soratvaziat":
            return (
              <Soratvaziat
                nextStep={this.inNextStep}
                prevStep={this.prevStep}
                onValueChange={onValueChange}
                values={values}
              ></Soratvaziat>
            );
          case "nemodar":
            return (
              <Nemodar
                nextStep={this.inNextStep}
                prevStep={this.prevStep}
                onValueChange={onValueChange}
                values={values}
              ></Nemodar>
            );
          case "ahjam":
            return (
              <Ahjam
                nextStep={this.inNextStep}
                prevStep={this.prevStep}
                onValueChange={onValueChange}
                values={values}
              ></Ahjam>
            );
          case "pardakhti":
            return (
              <Pardakhti
                nextStep={this.inNextStep}
                prevStep={this.prevStep}
                onValueChange={onValueChange}
                values={values}
              ></Pardakhti>
            );
          case "daryafti":
            return (
              <Daryafti
                nextStep={this.inNextStep}
                prevStep={this.prevStep}
                onValueChange={onValueChange}
                values={values}
              ></Daryafti>
            );
          case "vahedha":
            return (
              <Vahedha
                nextStep={this.inNextStep}
                prevStep={this.prevStep}
                onValueChange={onValueChange}
                values={values}
              ></Vahedha>
            );
          case "shahrak":
            return (
              <Shahrak
                nextStep={this.inNextStep}
                prevStep={this.prevStep}
                onValueChange={onValueChange}
                values={values}
              ></Shahrak>
            );
          default:
            break;
        }
        break;
      default:
        break;
    }
  }

  render() {
    const { inStep } = this.state;
    const { option, onValueChange, handleCheck } = this.props;
    const values = { option, handleCheck };

    return (
      <React.Fragment>
        <Header Title="سیستم انتخاب خانه" User="کاربر : کارفرما"></Header>
        {/* {this.renderSidebar(inStep)} */}
        {this.renderSwitch(inStep, values, onValueChange, handleCheck)}
      </React.Fragment>
    );
  }
}

export default Contractor;
